import React, { useEffect, useState } from 'react';
import './style/UpcomingMatchModal.css';
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';

if (process.env.REACT_APP_BUILD_ENV === 'production ') {
    console.log = function () { };
}


const UpcomingMatchupModal = ({ isOpen, onClose, matchId }) => {
    const [data, setData] = useState(null);
    const [homeTeam, setHomeTeam] = useState(null);
    const [awayTeam, setAwayTeam] = useState(null);
    const [stats, setStats] = useState(null);
    const [match, setMatch] = useState(null);

    useEffect(() => {
        if (matchId) {
            fetchData(matchId);
        }
    }, [matchId]);

    const fetchData = async (id) => {
        try {
            const response = await axiosInstance.get(`/stats/upcomingmatchup/?matchid=${id}`);
            setData(response.data);
            setMatch(response.data["Match"]);
            setHomeTeam(response.data["Home Team"]);
            setAwayTeam(response.data["Away Team"]);
            setStats(response.data["Stats"]);
        } catch (error) {
            toast.error('Failed to fetch data, try logging out and in again');
        }
    };

    const getCellClass = (homeValue, awayValue, format, invert = false) => {
        if (!format) {
            return ['', ''];
        }
        if (invert) {
            if (homeValue < awayValue) {
                return ['higher', 'lower'];
            } else if (homeValue > awayValue) {
                return ['lower', 'higher'];
            } else {
                return ['', ''];
            }
        }
        if (homeValue > awayValue) {
            return ['higher', 'lower'];
        } else if (homeValue < awayValue) {
            return ['lower', 'higher'];
        } else {
            return ['', ''];
        }
    };

    const formatStat = (value, totalValue = null, decimals = 3, suffix = '') => {
        if (value == null) return 'NA';
        let percentage = '';
        if (totalValue != null) {
            percentage = ` (${((value / totalValue) * 100).toFixed(decimals)}%)`;
        }
        return `${value.toFixed(decimals)}${percentage}${suffix}`;
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="upcoming-matchup-modal">
            <div className="upcoming-matchup-modal-content">
                <span className="upcoming-matchup-close" onClick={onClose}>&times;</span>
                <div className="upcoming-matchup-modal-body">
                    {data ? (
                        <div align="center">
                            <table className="matchup-table">
                                <thead className="matchup-table-header">
                                    <tr>
                                        <th></th>
                                        <th colSpan="2"><img src={homeTeam.imageDataURL} alt={`${homeTeam.name} logo`} /></th>
                                        <th></th>
                                        <th colSpan="2"><img src={awayTeam.imageDataURL} alt={`${awayTeam.name} logo`} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="team-names-row">
                                        <td> </td>
                                        <td colSpan="2" align="center" className="team-name">{homeTeam.name}</td>
                                        <td className="vs-column">vs</td>
                                        <td colSpan="2" align="center" className="team-name">{awayTeam.name}</td>
                                    </tr>
                                    <tr className="team-names-row">
                                        <td> </td>
                                        <td colSpan="5" align="center" className="team-name">{new Date(match.dateutc).toLocaleString()} {match.venue ? `at ${match.venue}` : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="stats-title"></td>
                                        <td colSpan="5" className="stats-title">Season Standings ({stats.area_name} - {stats.competition_name})</td>
                                    </tr>
                                    <tr className="hover-row">
                                        <td align="right">Matches Played:</td>
                                        <td align="center" colSpan="2">{stats.home_totalPlayed}</td>
                                        <td></td>
                                        <td align="center" colSpan="2">{stats.away_totalPlayed}</td>
                                    </tr>
                                    <tr className="hover-row">
                                        <td align="right">Wins:</td>
                                        <td align="center" colSpan="2">{stats.home_totalWins}</td>
                                        <td></td>
                                        <td align="center" colSpan="2">{stats.away_totalWins}</td>
                                    </tr>
                                    <tr className="hover-row">
                                        <td align="right">Draws:</td>
                                        <td align="center" colSpan="2">{stats.home_totalDraws}</td>
                                        <td></td>
                                        <td align="center" colSpan="2">{stats.away_totalDraws}</td>
                                    </tr>
                                    <tr className="hover-row">
                                        <td align="right">Losses:</td>
                                        <td align="center" colSpan="2">{stats.home_totalLosses}</td>
                                        <td></td>
                                        <td align="center" colSpan="2">{stats.away_totalLosses}</td>
                                    </tr>
                                    <tr className="hover-row">
                                        <td align="right">Total Points:</td>
                                        <td align="center" colSpan="2">{stats.home_totalPoints}</td>
                                        <td></td>
                                        <td align="center" colSpan="2">{stats.away_totalPoints}</td>
                                    </tr>
                                    <tr className="hover-row">
                                        <td align="right">Goals For:</td>
                                        <td align="center" colSpan="2">{stats.home_totalGoalsFor}</td>
                                        <td></td>
                                        <td align="center" colSpan="2">{stats.away_totalGoalsFor}</td>
                                    </tr>
                                    <tr className="hover-row">
                                        <td align="right">Goals Against:</td>
                                        <td align="center" colSpan="2">{stats.home_totalGoalsAgainst}</td>
                                        <td></td>
                                        <td align="center" colSpan="2">{stats.away_totalGoalsAgainst}</td>
                                    </tr>

                                    <tr>
                                        <td className="stats-title"></td>
                                        <td colSpan="5" className="stats-title">Season Statistics (Per Match Averages)</td>
                                    </tr>
                                    <tr>
                                        <td className="stats-category"></td>
                                        <td colSpan="5" className="stats-category">General</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <td>at Home</td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Away</td>
                                    </tr>
                                    {[
                                        {
                                            label: "xG",
                                            homeStat: stats.home_season_stats.general_xg,
                                            homeStatHome: stats.home_season_home_stats.general_xg,
                                            awayStat: stats.away_season_stats.general_xg,
                                            awayStatAway: stats.away_season_away_stats.general_xg,
                                            format: true,
                                        },
                                        {
                                            label: "xG Per Shot",
                                            homeStat: stats.home_season_stats.general_xgPerShot,
                                            homeStatHome: stats.home_season_home_stats.general_xgPerShot,
                                            awayStat: stats.away_season_stats.general_xgPerShot,
                                            awayStatAway: stats.away_season_away_stats.general_xgPerShot,
                                            format: true,
                                        },
                                        {
                                            label: "xG Conceded",
                                            homeStat: stats.home_xgc,
                                            homeStatHome: stats.home_xgc_home,
                                            awayStat: stats.away_xgc,
                                            awayStatAway: stats.away_xgc_away,
                                            format: true,
                                            invert: true,
                                        },
                                        {
                                            label: "Goals",
                                            homeStat: stats.home_season_stats.general_goals,
                                            homeStatHome: stats.home_season_home_stats.general_goals,
                                            awayStat: stats.away_season_stats.general_goals,
                                            awayStatAway: stats.away_season_away_stats.general_goals,
                                            format: true,
                                        },
                                        {
                                            label: "Shots",
                                            homeStat: stats.home_season_stats.general_shots,
                                            homeStatHome: stats.home_season_home_stats.general_shots,
                                            awayStat: stats.away_season_stats.general_shots,
                                            awayStatAway: stats.away_season_away_stats.general_shots,
                                            format: true,
                                        },
                                        {
                                            label: "Shots on Target",
                                            homeStat: stats.home_season_stats.general_shotsOnTarget,
                                            homeStatHome: stats.home_season_home_stats.general_shotsOnTarget,
                                            awayStat: stats.away_season_stats.general_shotsOnTarget,
                                            awayStatAway: stats.away_season_away_stats.general_shotsOnTarget,
                                            format: true,
                                        },
                                        {
                                            label: "Corners",
                                            homeStat: stats.home_season_stats.general_corners,
                                            homeStatHome: stats.home_season_home_stats.general_corners,
                                            awayStat: stats.away_season_stats.general_corners,
                                            awayStatAway: stats.away_season_away_stats.general_corners,
                                            format: true,
                                        },
                                        {
                                            label: "Free Kicks",
                                            homeStat: stats.home_season_stats.general_freeKicks,
                                            homeStatHome: stats.home_season_home_stats.general_freeKicks,
                                            awayStat: stats.away_season_stats.general_freeKicks,
                                            awayStatAway: stats.away_season_away_stats.general_freeKicks,
                                            format: true,
                                        },
                                        {
                                            label: "Fouls",
                                            homeStat: stats.home_season_stats.general_fouls,
                                            homeStatHome: stats.home_season_home_stats.general_fouls,
                                            awayStat: stats.away_season_stats.general_fouls,
                                            awayStatAway: stats.away_season_away_stats.general_fouls,
                                            format: true,
                                            invert: true,
                                        },
                                        {
                                            label: "Fouls Suffered",
                                            homeStat: stats.home_season_stats.general_foulsSuffered,
                                            homeStatHome: stats.home_season_home_stats.general_foulsSuffered,
                                            awayStat: stats.away_season_stats.general_foulsSuffered,
                                            awayStatAway: stats.away_season_away_stats.general_foulsSuffered,
                                            format: true,

                                        },
                                        {
                                            label: "Dribbles",
                                            homeStat: stats.home_season_stats.general_dribbles,
                                            homeStatHome: stats.home_season_home_stats.general_dribbles,
                                            awayStat: stats.away_season_stats.general_dribbles,
                                            awayStatAway: stats.away_season_away_stats.general_dribbles,
                                            format: true,
                                        },
                                        {
                                            label: "Offisdes",
                                            homeStat: stats.home_season_stats.general_offsides,
                                            homeStatHome: stats.home_season_home_stats.general_offsides,
                                            awayStat: stats.away_season_stats.general_offsides,
                                            awayStatAway: stats.away_season_away_stats.general_offsides,
                                            format: true,
                                            invert: true,
                                        },
                                        {
                                            label: "Yellow Cards",
                                            homeStat: stats.home_season_stats.general_yellowCards,
                                            homeStatHome: stats.home_season_home_stats.general_yellowCards,
                                            awayStat: stats.away_season_stats.general_yellowCards,
                                            awayStatAway: stats.away_season_away_stats.general_yellowCards,
                                            format: true,
                                            invert: true,
                                        },
                                        {
                                            label: "Red Cards",
                                            homeStat: stats.home_season_stats.general_redCards,
                                            homeStatHome: stats.home_season_home_stats.general_redCards,
                                            awayStat: stats.away_season_stats.general_redCards,
                                            awayStatAway: stats.away_season_away_stats.general_redCards,
                                            format: true,
                                            invert: true,
                                        },
                                        {
                                            label: "Progressive Runs",
                                            homeStat: stats.home_season_stats.general_progressiveRuns,
                                            homeStatHome: stats.home_season_home_stats.general_progressiveRuns,
                                            awayStat: stats.away_season_stats.general_progressiveRuns,
                                            awayStatAway: stats.away_season_away_stats.general_progressiveRuns,
                                            format: true,
                                        },
                                        {
                                            label: "Touches in Box",
                                            homeStat: stats.home_season_stats.general_touchesInBox,
                                            homeStatHome: stats.home_season_home_stats.general_touchesInBox,
                                            awayStat: stats.away_season_stats.general_touchesInBox,
                                            awayStatAway: stats.away_season_away_stats.general_touchesInBox,
                                            format: true,
                                        }
                                    ].map((stat) => {
                                        const [homeClass, awayClass] = getCellClass(stat.homeStat, stat.awayStat, stat.format, stat.invert);
                                        const [homeAtHomeClass, awayAtAwayClass] = getCellClass(stat.homeStatHome, stat.awayStatAway, stat.format, stat.invert);


                                        return (
                                            <tr className="hover-row" key={stat.label}>
                                                <td align="right">{stat.label}:</td>
                                                <td align="center" className={homeClass}>{formatStat(stat.homeStat, stat.totalHomeStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={homeAtHomeClass}>{formatStat(stat.homeStatHome, stat.totalHomeStatHome, stat.decimals, stat.suffix)}</td>
                                                <td></td>
                                                <td align="center" className={awayClass}>{formatStat(stat.awayStat, stat.totalAwayStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={awayAtAwayClass}>{formatStat(stat.awayStatAway, stat.totalAwayStatAway, stat.decimals, stat.suffix)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td className="stats-category"></td>
                                        <td colSpan="5" className="stats-category">Possession</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <td>at Home</td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Away</td>
                                    </tr>
                                    {[
                                        {
                                            label: "Possession %",
                                            homeStat: stats.home_season_stats.possession_possessionPercent,
                                            homeStatHome: stats.home_season_home_stats.possession_possessionPercent,
                                            awayStat: stats.away_season_stats.possession_possessionPercent,
                                            awayStatAway: stats.away_season_away_stats.possession_possessionPercent,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (Opponent Half)",
                                            homeStat: stats.home_season_stats.possession_reachingOpponentHalf,
                                            homeStatHome: stats.home_season_home_stats.possession_reachingOpponentHalf,
                                            awayStat: stats.away_season_stats.possession_reachingOpponentHalf,
                                            awayStatAway: stats.away_season_away_stats.possession_reachingOpponentHalf,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (Opponent Box)",
                                            homeStat: stats.home_season_stats.possession_reachingOpponentBox,
                                            homeStatHome: stats.home_season_home_stats.possession_reachingOpponentBox,
                                            awayStat: stats.away_season_stats.possession_reachingOpponentBox,
                                            awayStatAway: stats.away_season_away_stats.possession_reachingOpponentBox,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (1-15)",
                                            homeStat: stats.home_season_stats.possession_possession1_15,
                                            homeStatHome: stats.home_season_home_stats.possession_possession1_15,
                                            awayStat: stats.away_season_stats.possession_possession1_15,
                                            awayStatAway: stats.away_season_away_stats.possession_possession1_15,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (16-30)",
                                            homeStat: stats.home_season_stats.possession_possession16_30,
                                            homeStatHome: stats.home_season_home_stats.possession_possession16_30,
                                            awayStat: stats.away_season_stats.possession_possession16_30,
                                            awayStatAway: stats.away_season_away_stats.possession_possession16_30,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (31-45)",
                                            homeStat: stats.home_season_stats.possession_possession31_45,
                                            homeStatHome: stats.home_season_home_stats.possession_possession31_45,
                                            awayStat: stats.away_season_stats.possession_possession31_45,
                                            awayStatAway: stats.away_season_away_stats.possession_possession31_45,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (46-60)",
                                            homeStat: stats.home_season_stats.possession_possession46_60,
                                            homeStatHome: stats.home_season_home_stats.possession_possession46_60,
                                            awayStat: stats.away_season_stats.possession_possession46_60,
                                            awayStatAway: stats.away_season_away_stats.possession_possession46_60,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (61-75)",
                                            homeStat: stats.home_season_stats.possession_possession61_75,
                                            homeStatHome: stats.home_season_home_stats.possession_possession61_75,
                                            awayStat: stats.away_season_stats.possession_possession61_75,
                                            awayStatAway: stats.away_season_away_stats.possession_possession61_75,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Possession % (76-90)",
                                            homeStat: stats.home_season_stats.possession_possession76_90,
                                            homeStatHome: stats.home_season_home_stats.possession_possession76_90,
                                            awayStat: stats.away_season_stats.possession_possession76_90,
                                            awayStatAway: stats.away_season_away_stats.possession_possession76_90,
                                            decimals: 2,
                                            suffix: '%',
                                            format: true,
                                        },
                                        {
                                            label: "Average Possession Duration",
                                            homeStat: stats.home_season_stats.possession_avgPossessionDuration * 60,
                                            homeStatHome: stats.home_season_home_stats.possession_avgPossessionDuration * 60,
                                            awayStat: stats.away_season_stats.possession_avgPossessionDuration * 60,
                                            awayStatAway: stats.away_season_away_stats.possession_avgPossessionDuration * 60,
                                            decimals: 2,
                                            suffix: ' s',
                                            format: true,
                                        }
                                    ].map((stat) => {
                                        const [homeClass, awayClass] = getCellClass(stat.homeStat, stat.awayStat, stat.format);
                                        const [homeAtHomeClass, awayAtAwayClass] = getCellClass(stat.homeStatHome, stat.awayStatAway, stat.format);

                                        return (
                                            <tr className="hover-row" key={stat.label}>
                                                <td align="right">{stat.label}:</td>
                                                <td align="center" className={homeClass}>{formatStat(stat.homeStat, stat.totalHomeStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={homeAtHomeClass}>{formatStat(stat.homeStatHome, stat.totalHomeStatHome, stat.decimals, stat.suffix)}</td>
                                                <td></td>
                                                <td align="center" className={awayClass}>{formatStat(stat.awayStat, stat.totalAwayStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={awayAtAwayClass}>{formatStat(stat.awayStatAway, stat.totalAwayStatAway, stat.decimals, stat.suffix)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td className="stats-category"></td>
                                        <td colSpan="5" className="stats-category">Attack</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <td>at Home</td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Away</td>
                                    </tr>
                                    {[
                                        {
                                            label: "Corners",
                                            homeStat: stats.home_season_stats.attack_corners,
                                            homeStatHome: stats.home_season_home_stats.attack_corners,
                                            awayStat: stats.away_season_stats.attack_corners,
                                            awayStatAway: stats.away_season_away_stats.attack_corners,
                                            format: true,
                                        },
                                        {
                                            label: "Corners with Shots %",
                                            homeStat: stats.home_season_stats.attack_cornersWithShot / stats.home_season_stats.attack_corners * 100,
                                            homeStatHome: stats.home_season_home_stats.attack_cornersWithShot / stats.home_season_home_stats.attack_corners * 100,
                                            awayStat: stats.away_season_stats.attack_cornersWithShot / stats.away_season_stats.attack_corners * 100,
                                            awayStatAway: stats.away_season_away_stats.attack_cornersWithShot / stats.away_season_away_stats.attack_corners * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Counter Attacks",
                                            homeStat: stats.home_season_stats.attack_counterAttacks,
                                            homeStatHome: stats.home_season_home_stats.attack_counterAttacks,
                                            awayStat: stats.away_season_stats.attack_counterAttacks,
                                            awayStatAway: stats.away_season_away_stats.attack_counterAttacks,
                                            format: true,
                                        },
                                        {
                                            label: "Free Kicks",
                                            homeStat: stats.home_season_stats.attack_freeKicks,
                                            homeStatHome: stats.home_season_home_stats.attack_freeKicks,
                                            awayStat: stats.away_season_stats.attack_freeKicks,
                                            awayStatAway: stats.away_season_away_stats.attack_freeKicks,
                                            format: true,
                                        },
                                        {
                                            label: "Free Kicks with Shots %",
                                            homeStat: stats.home_season_stats.attack_freeKicksWithShot / stats.home_season_stats.attack_freeKicks * 100,
                                            homeStatHome: stats.home_season_home_stats.attack_freeKicksWithShot / stats.home_season_home_stats.attack_freeKicks * 100,
                                            awayStat: stats.away_season_stats.attack_freeKicksWithShot / stats.away_season_stats.attack_freeKicks * 100,
                                            awayStatAway: stats.away_season_away_stats.attack_freeKicksWithShot / stats.away_season_away_stats.attack_freeKicks * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Positional Attacks",
                                            homeStat: stats.home_season_stats.attack_positionalAttack,
                                            homeStatHome: stats.home_season_home_stats.attack_positionalAttack,
                                            awayStat: stats.away_season_stats.attack_positionalAttack,
                                            awayStatAway: stats.away_season_away_stats.attack_positionalAttack,
                                            format: true,
                                        },
                                        {
                                            label: "Possitional Attacks with Shots %",
                                            homeStat: stats.home_season_stats.attack_positionalWithShots / stats.home_season_stats.attack_positionalAttack * 100,
                                            homeStatHome: stats.home_season_home_stats.attack_positionalWithShots / stats.home_season_home_stats.attack_positionalAttack * 100,
                                            awayStat: stats.away_season_stats.attack_positionalWithShots / stats.away_season_stats.attack_positionalAttack * 100,
                                            awayStatAway: stats.away_season_away_stats.attack_positionalWithShots / stats.away_season_away_stats.attack_positionalAttack * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Total Attacks",
                                            homeStat: stats.home_season_stats.attack_total,
                                            homeStatHome: stats.home_season_home_stats.attack_total,
                                            awayStat: stats.away_season_stats.attack_total,
                                            awayStatAway: stats.away_season_away_stats.attack_total,
                                            format: true,
                                        },
                                        {
                                            label: "Total Attacks with Shots %",
                                            homeStat: stats.home_season_stats.attack_withShots / stats.home_season_stats.attack_total * 100,
                                            homeStatHome: stats.home_season_home_stats.attack_withShots / stats.home_season_home_stats.attack_total * 100,
                                            awayStat: stats.away_season_stats.attack_withShots / stats.away_season_stats.attack_total * 100,
                                            awayStatAway: stats.away_season_away_stats.attack_withShots / stats.away_season_away_stats.attack_total * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',

                                        },
                                        /* Including flanks under Attack */
                                        {
                                            label: "Center Attacks",
                                            homeStat: stats.home_season_stats.flanks_centerAttacks,
                                            totalHomeStat: stats.home_season_stats.attack_total,
                                            homeStatHome: stats.home_season_home_stats.flanks_centerAttacks,
                                            totalHomeStatHome: stats.home_season_home_stats.attack_total,
                                            awayStat: stats.away_season_stats.flanks_centerAttacks,
                                            totalAwayStat: stats.away_season_stats.attack_total,
                                            awayStatAway: stats.away_season_away_stats.flanks_centerAttacks,
                                            totalAwayStatAway: stats.away_season_away_stats.attack_total,
                                            format: true,
                                            decimals: 1,
                                        },
                                        {
                                            label: "Center Attack xG",
                                            homeStat: stats.home_season_stats.flanks_centerXg,
                                            homeStatHome: stats.home_season_home_stats.flanks_centerXg,
                                            awayStat: stats.away_season_stats.flanks_centerXg,
                                            awayStatAway: stats.away_season_away_stats.flanks_centerXg,
                                            format: true,
                                        },
                                        {
                                            label: "Left Flank Attacks",
                                            homeStat: stats.home_season_stats.flanks_leftFlankAttacks,
                                            totalHomeStat: stats.home_season_stats.attack_total,
                                            homeStatHome: stats.home_season_home_stats.flanks_leftFlankAttacks,
                                            totalHomeStatHome: stats.home_season_home_stats.attack_total,
                                            awayStat: stats.away_season_stats.flanks_leftFlankAttacks,
                                            totalAwayStat: stats.away_season_stats.attack_total,
                                            awayStatAway: stats.away_season_away_stats.flanks_leftFlankAttacks,
                                            totalAwayStatAway: stats.away_season_away_stats.attack_total,
                                            format: true,
                                            decimals: 1,
                                        },
                                        {
                                            label: "Left Flank xG",
                                            homeStat: stats.home_season_stats.flanks_leftFlankXg,
                                            homeStatHome: stats.home_season_home_stats.flanks_leftFlankXg,
                                            awayStat: stats.away_season_stats.flanks_leftFlankXg,
                                            awayStatAway: stats.away_season_away_stats.flanks_leftFlankXg,
                                            format: true,
                                        },
                                        {
                                            label: "Right Flank Attacks",
                                            homeStat: stats.home_season_stats.flanks_rightFlankAttacks,
                                            totalHomeStat: stats.home_season_stats.attack_total,
                                            homeStatHome: stats.home_season_home_stats.flanks_rightFlankAttacks,
                                            totalHomeStatHome: stats.home_season_home_stats.attack_total,
                                            awayStat: stats.away_season_stats.flanks_rightFlankAttacks,
                                            totalAwayStat: stats.away_season_stats.attack_total,
                                            awayStatAway: stats.away_season_away_stats.flanks_rightFlankAttacks,
                                            totalAwayStatAway: stats.away_season_away_stats.attack_total,
                                            format: true,
                                            decimals: 1,
                                        },
                                        {
                                            label: "Right Flank xG",
                                            homeStat: stats.home_season_stats.flanks_rightFlankXg,
                                            homeStatHome: stats.home_season_home_stats.flanks_rightFlankXg,
                                            awayStat: stats.away_season_stats.flanks_rightFlankXg,
                                            awayStatAway: stats.away_season_away_stats.flanks_rightFlankXg,
                                            format: true,
                                        },
                                    ].map((stat) => {
                                        const [homeClass, awayClass] = getCellClass(stat.homeStat, stat.awayStat, stat.format, stat.invert);
                                        const [homeAtHomeClass, awayAtAwayClass] = getCellClass(stat.homeStatHome, stat.awayStatAway, stat.format, stat.invert);


                                        return (
                                            <tr className="hover-row" key={stat.label}>
                                                <td align="right">{stat.label}:</td>
                                                <td align="center" className={homeClass}>{formatStat(stat.homeStat, stat.totalHomeStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={homeAtHomeClass}>{formatStat(stat.homeStatHome, stat.totalHomeStatHome, stat.decimals, stat.suffix)}</td>
                                                <td></td>
                                                <td align="center" className={awayClass}>{formatStat(stat.awayStat, stat.totalAwayStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={awayAtAwayClass}>{formatStat(stat.awayStatAway, stat.totalAwayStatAway, stat.decimals, stat.suffix)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td className="stats-category"></td>
                                        <td colSpan="5" className="stats-category">Defense</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <td>at Home</td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Away</td>
                                    </tr>
                                    {[
                                        {
                                            label: "Clearances",
                                            homeStat: stats.home_season_stats.defence_clearances,
                                            homeStatHome: stats.home_season_home_stats.defence_clearances,
                                            awayStat: stats.away_season_stats.defence_clearances,
                                            awayStatAway: stats.away_season_away_stats.defence_clearances,
                                            format: false,
                                        },
                                        {
                                            label: "Interceptions",
                                            homeStat: stats.home_season_stats.defence_interceptions,
                                            homeStatHome: stats.home_season_home_stats.defence_interceptions,
                                            awayStat: stats.away_season_stats.defence_interceptions,
                                            awayStatAway: stats.away_season_away_stats.defence_interceptions,
                                            format: true,
                                        },
                                        {
                                            label: "Passes / Defensive Action",
                                            homeStat: stats.home_season_stats.defence_ppda,
                                            homeStatHome: stats.home_season_home_stats.defence_ppda,
                                            awayStat: stats.away_season_stats.defence_ppda,
                                            awayStatAway: stats.away_season_away_stats.defence_ppda,
                                            format: true,
                                            invert: true,
                                        },
                                        {
                                            label: "Tackles",
                                            homeStat: stats.home_season_stats.defence_tackles,
                                            homeStatHome: stats.home_season_home_stats.defence_tackles,
                                            awayStat: stats.away_season_stats.defence_tackles,
                                            awayStatAway: stats.away_season_away_stats.defence_tackles,
                                            format: true,
                                        },
                                    ].map((stat) => {
                                        const [homeClass, awayClass] = getCellClass(stat.homeStat, stat.awayStat, stat.format, stat.invert);
                                        const [homeAtHomeClass, awayAtAwayClass] = getCellClass(stat.homeStatHome, stat.awayStatAway, stat.format, stat.invert);

                                        return (
                                            <tr className="hover-row" key={stat.label}>
                                                <td align="right">{stat.label}:</td>
                                                <td align="center" className={homeClass}>{formatStat(stat.homeStat, stat.totalHomeStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={homeAtHomeClass}>{formatStat(stat.homeStatHome, stat.totalHomeStatHome, stat.decimals, stat.suffix)}</td>
                                                <td></td>
                                                <td align="center" className={awayClass}>{formatStat(stat.awayStat, stat.totalAwayStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={awayAtAwayClass}>{formatStat(stat.awayStatAway, stat.totalAwayStatAway, stat.decimals, stat.suffix)}</td>
                                            </tr>
                                        );
                                    }
                                    )}
                                    <tr>
                                        <td className="stats-category"></td>
                                        <td colSpan="5" className="stats-category">Duels</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <td>at Home</td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Away</td>
                                    </tr>
                                    {[
                                        {
                                            label: "Challenge Intensity",
                                            homeStat: stats.home_season_stats.duels_challengeIntensity,
                                            homeStatHome: stats.home_season_home_stats.duels_challengeIntensity,
                                            awayStat: stats.away_season_stats.duels_challengeIntensity,
                                            awayStatAway: stats.away_season_away_stats.duels_challengeIntensity,
                                            format: true,
                                        },
                                        {
                                            label: "Duels",
                                            homeStat: stats.home_season_stats.duels_duels,
                                            homeStatHome: stats.home_season_home_stats.duels_duels,
                                            awayStat: stats.away_season_stats.duels_duels,
                                            awayStatAway: stats.away_season_away_stats.duels_duels,
                                            format: true,
                                        },
                                        {
                                            label: "Duels Successful %",
                                            homeStat: stats.home_season_stats.duels_duelsSuccessful / stats.home_season_stats.duels_duels * 100,
                                            homeStatHome: stats.home_season_home_stats.duels_duelsSuccessful / stats.home_season_home_stats.duels_duels * 100,
                                            awayStat: stats.away_season_stats.duels_duelsSuccessful / stats.away_season_stats.duels_duels * 100,
                                            awayStatAway: stats.away_season_away_stats.duels_duelsSuccessful / stats.away_season_away_stats.duels_duels * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Aerial Duels",
                                            homeStat: stats.home_season_stats.duels_aerialDuels,
                                            homeStatHome: stats.home_season_home_stats.duels_aerialDuels,
                                            awayStat: stats.away_season_stats.duels_aerialDuels,
                                            awayStatAway: stats.away_season_away_stats.duels_aerialDuels,
                                            format: true,
                                        },
                                        {
                                            label: "Aerial Duels Successful %",
                                            homeStat: stats.home_season_stats.duels_aerialDuelsSuccessful / stats.home_season_stats.duels_aerialDuels * 100,
                                            homeStatHome: stats.home_season_home_stats.duels_aerialDuelsSuccessful / stats.home_season_home_stats.duels_aerialDuels * 100,
                                            awayStat: stats.away_season_stats.duels_aerialDuelsSuccessful / stats.away_season_stats.duels_aerialDuels * 100,
                                            awayStatAway: stats.away_season_away_stats.duels_aerialDuelsSuccessful / stats.away_season_away_stats.duels_aerialDuels * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Defensive Duels",
                                            homeStat: stats.home_season_stats.duels_defensiveDuels,
                                            homeStatHome: stats.home_season_home_stats.duels_defensiveDuels,
                                            awayStat: stats.away_season_stats.duels_defensiveDuels,
                                            awayStatAway: stats.away_season_away_stats.duels_defensiveDuels,
                                            format: true,
                                        },
                                        {
                                            label: "Defensive Duels Successful %",
                                            homeStat: stats.home_season_stats.duels_defensiveDuelsSuccessful / stats.home_season_stats.duels_defensiveDuels * 100,
                                            homeStatHome: stats.home_season_home_stats.duels_defensiveDuelsSuccessful / stats.home_season_home_stats.duels_defensiveDuels * 100,
                                            awayStat: stats.away_season_stats.duels_defensiveDuelsSuccessful / stats.away_season_stats.duels_defensiveDuels * 100,
                                            awayStatAway: stats.away_season_away_stats.duels_defensiveDuelsSuccessful / stats.away_season_away_stats.duels_defensiveDuels * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Ground Duels",
                                            homeStat: stats.home_season_stats.duels_groundDuels,
                                            homeStatHome: stats.home_season_home_stats.duels_groundDuels,
                                            awayStat: stats.away_season_stats.duels_groundDuels,
                                            awayStatAway: stats.away_season_away_stats.duels_groundDuels,
                                            format: true,
                                        },
                                        {
                                            label: "Ground Duels Successful %",
                                            homeStat: stats.home_season_stats.duels_groundDuelsSuccessful / stats.home_season_stats.duels_groundDuels * 100,
                                            homeStatHome: stats.home_season_home_stats.duels_groundDuelsSuccessful / stats.home_season_home_stats.duels_groundDuels * 100,
                                            awayStat: stats.away_season_stats.duels_groundDuelsSuccessful / stats.away_season_stats.duels_groundDuels * 100,
                                            awayStatAway: stats.away_season_away_stats.duels_groundDuelsSuccessful / stats.away_season_away_stats.duels_groundDuels * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Loose Ball Duels",
                                            homeStat: stats.home_season_stats.duels_looseBallDuels,
                                            homeStatHome: stats.home_season_home_stats.duels_looseBallDuels,
                                            awayStat: stats.away_season_stats.duels_looseBallDuels,
                                            awayStatAway: stats.away_season_away_stats.duels_looseBallDuels,
                                            format: true,
                                        },
                                        {
                                            label: "Loose Ball Duels Successful %",
                                            homeStat: stats.home_season_stats.duels_looseBallDuelsSuccessful / stats.home_season_stats.duels_looseBallDuels * 100,
                                            homeStatHome: stats.home_season_home_stats.duels_looseBallDuelsSuccessful / stats.home_season_home_stats.duels_looseBallDuels * 100,
                                            awayStat: stats.away_season_stats.duels_looseBallDuelsSuccessful / stats.away_season_stats.duels_looseBallDuels * 100,
                                            awayStatAway: stats.away_season_away_stats.duels_looseBallDuelsSuccessful / stats.away_season_away_stats.duels_looseBallDuels * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Offensive Duels",
                                            homeStat: stats.home_season_stats.duels_offensiveDuels,
                                            homeStatHome: stats.home_season_home_stats.duels_offensiveDuels,
                                            awayStat: stats.away_season_stats.duels_offensiveDuels,
                                            awayStatAway: stats.away_season_away_stats.duels_offensiveDuels,
                                            format: true,
                                        },
                                        {
                                            label: "Offensive Duels Successful %",
                                            homeStat: stats.home_season_stats.duels_offensiveDuelsSuccessful / stats.home_season_stats.duels_offensiveDuels * 100,
                                            homeStatHome: stats.home_season_home_stats.duels_offensiveDuelsSuccessful / stats.home_season_home_stats.duels_offensiveDuels * 100,
                                            awayStat: stats.away_season_stats.duels_offensiveDuelsSuccessful / stats.away_season_stats.duels_offensiveDuels * 100,
                                            awayStatAway: stats.away_season_away_stats.duels_offensiveDuelsSuccessful / stats.away_season_away_stats.duels_offensiveDuels * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Dribbles",
                                            homeStat: stats.home_season_stats.duels_dribbles,
                                            homeStatHome: stats.home_season_home_stats.duels_dribbles,
                                            awayStat: stats.away_season_stats.duels_dribbles,
                                            awayStatAway: stats.away_season_away_stats.duels_dribbles,
                                            format: true,
                                        },
                                        {
                                            label: "Dribbles Successful %",
                                            homeStat: stats.home_season_stats.duels_dribblesSuccessful / stats.home_season_stats.duels_dribbles * 100,
                                            homeStatHome: stats.home_season_home_stats.duels_dribblesSuccessful / stats.home_season_home_stats.duels_dribbles * 100,
                                            awayStat: stats.away_season_stats.duels_dribblesSuccessful / stats.away_season_stats.duels_dribbles * 100,
                                            awayStatAway: stats.away_season_away_stats.duels_dribblesSuccessful / stats.away_season_away_stats.duels_dribbles * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },

                                    ]
                                        .map((stat) => {
                                            const [homeClass, awayClass] = getCellClass(stat.homeStat, stat.awayStat, stat.format, stat.invert);
                                            const [homeAtHomeClass, awayAtAwayClass] = getCellClass(stat.homeStatHome, stat.awayStatAway, stat.format, stat.invert);

                                            return (
                                                <tr className="hover-row" key={stat.label}>
                                                    <td align="right">{stat.label}:</td>
                                                    <td align="center" className={homeClass}>{formatStat(stat.homeStat, stat.totalHomeStat, stat.decimals, stat.suffix)}</td>
                                                    <td align="center" className={homeAtHomeClass}>{formatStat(stat.homeStatHome, stat.totalHomeStatHome, stat.decimals, stat.suffix)}</td>
                                                    <td></td>
                                                    <td align="center" className={awayClass}>{formatStat(stat.awayStat, stat.totalAwayStat, stat.decimals, stat.suffix)}</td>
                                                    <td align="center" className={awayAtAwayClass}>{formatStat(stat.awayStatAway, stat.totalAwayStatAway, stat.decimals, stat.suffix)}</td>
                                                </tr>
                                            );
                                        })}
                                    <tr>
                                        <td className="stats-category"></td>
                                        <td colSpan="5" className="stats-category">Transitions</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <td>at Home</td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Away</td>
                                    </tr>
                                    {[
                                        {
                                            label: "Losses High",
                                            homeStat: stats.home_season_stats.transitions_lossesHigh,
                                            totalHomeStat: stats.home_season_stats.transitions_lossesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_lossesHigh,
                                            totalHomeStatHome: stats.home_season_home_stats.transitions_lossesTotal,
                                            awayStat: stats.away_season_stats.transitions_lossesHigh,
                                            totalAwayStat: stats.away_season_stats.transitions_lossesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_lossesHigh,
                                            totalAwayStatAway: stats.away_season_away_stats.transitions_lossesTotal,
                                            format: true,
                                            invert: true,
                                            decimals: 1,
                                        },
                                        {
                                            label: "Losses Low",
                                            homeStat: stats.home_season_stats.transitions_lossesLow,
                                            totalHomeStat: stats.home_season_stats.transitions_lossesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_lossesLow,
                                            totalHomeStatHome: stats.home_season_home_stats.transitions_lossesTotal,
                                            awayStat: stats.away_season_stats.transitions_lossesLow,
                                            totalAwayStat: stats.away_season_stats.transitions_lossesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_lossesLow,
                                            totalAwayStatAway: stats.away_season_away_stats.transitions_lossesTotal,
                                            format: true,
                                            invert: true,
                                            decimals: 1,
                                        },
                                        {
                                            label: "Losses Medium",
                                            homeStat: stats.home_season_stats.transitions_lossesMedium,
                                            totalHomeStat: stats.home_season_stats.transitions_lossesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_lossesMedium,
                                            totalHomeStatHome: stats.home_season_home_stats.transitions_lossesTotal,
                                            awayStat: stats.away_season_stats.transitions_lossesMedium,
                                            totalAwayStat: stats.away_season_stats.transitions_lossesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_lossesMedium,
                                            totalAwayStatAway: stats.away_season_away_stats.transitions_lossesTotal,
                                            format: true,
                                            invert: true,
                                            decimals: 1,
                                        },
                                        {
                                            label: "Losses Total",
                                            homeStat: stats.home_season_stats.transitions_lossesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_lossesTotal,
                                            awayStat: stats.away_season_stats.transitions_lossesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_lossesTotal,
                                            format: true,
                                            invert: true,

                                        },
                                        {
                                            label: "Opponent Half Recoveries",
                                            homeStat: stats.home_season_stats.transitions_opponentHalfRecoveries,
                                            homeStatHome: stats.home_season_home_stats.transitions_opponentHalfRecoveries,
                                            awayStat: stats.away_season_stats.transitions_opponentHalfRecoveries,
                                            awayStatAway: stats.away_season_away_stats.transitions_opponentHalfRecoveries,
                                            format: true,
                                        },
                                        {
                                            label: "Own Half Losses",
                                            homeStat: stats.home_season_stats.transitions_ownHalfLosses,
                                            homeStatHome: stats.home_season_home_stats.transitions_ownHalfLosses,
                                            awayStat: stats.away_season_stats.transitions_ownHalfLosses,
                                            awayStatAway: stats.away_season_away_stats.transitions_ownHalfLosses,
                                            format: true,
                                            invert: true,
                                        },
                                        {
                                            label: "Recoveries High",
                                            homeStat: stats.home_season_stats.transitions_recoveriesHigh,
                                            totalHomeStat: stats.home_season_stats.transitions_recoveriesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_recoveriesHigh,
                                            totalHomeStatHome: stats.home_season_home_stats.transitions_recoveriesTotal,
                                            awayStat: stats.away_season_stats.transitions_recoveriesHigh,
                                            totalAwayStat: stats.away_season_stats.transitions_recoveriesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_recoveriesHigh,
                                            totalAwayStatAway: stats.away_season_away_stats.transitions_recoveriesTotal,
                                            format: true,
                                            decimals: 1,
                                        },
                                        {
                                            label: "Recoveries Low",
                                            homeStat: stats.home_season_stats.transitions_recoveriesLow,
                                            totalHomeStat: stats.home_season_stats.transitions_recoveriesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_recoveriesLow,
                                            totalHomeStatHome: stats.home_season_home_stats.transitions_recoveriesTotal,
                                            awayStat: stats.away_season_stats.transitions_recoveriesLow,
                                            totalAwayStat: stats.away_season_stats.transitions_recoveriesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_recoveriesLow,
                                            totalAwayStatAway: stats.away_season_away_stats.transitions_recoveriesTotal,
                                            format: true,
                                            decimals: 1,

                                        },
                                        {
                                            label: "Recoveries Medium",
                                            homeStat: stats.home_season_stats.transitions_recoveriesMedium,
                                            totalHomeStat: stats.home_season_stats.transitions_recoveriesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_recoveriesMedium,
                                            totalHomeStatHome: stats.home_season_home_stats.transitions_recoveriesTotal,
                                            awayStat: stats.away_season_stats.transitions_recoveriesMedium,
                                            totalAwayStat: stats.away_season_stats.transitions_recoveriesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_recoveriesMedium,
                                            totalAwayStatAway: stats.away_season_away_stats.transitions_recoveriesTotal,
                                            format: true,
                                            decimals: 1,

                                        },
                                        {
                                            label: "Recoveries Total",
                                            homeStat: stats.home_season_stats.transitions_recoveriesTotal,
                                            homeStatHome: stats.home_season_home_stats.transitions_recoveriesTotal,
                                            awayStat: stats.away_season_stats.transitions_recoveriesTotal,
                                            awayStatAway: stats.away_season_away_stats.transitions_recoveriesTotal,
                                            format: true,

                                        },
                                    ].map((stat) => {
                                        const [homeClass, awayClass] = getCellClass(stat.homeStat, stat.awayStat, stat.format, stat.invert);
                                        const [homeAtHomeClass, awayAtAwayClass] = getCellClass(stat.homeStatHome, stat.awayStatAway, stat.format, stat.invert);

                                        return (
                                            <tr className="hover-row" key={stat.label}>
                                                <td align="right">{stat.label}:</td>
                                                <td align="center" className={homeClass}>{formatStat(stat.homeStat, stat.totalHomeStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={homeAtHomeClass}>{formatStat(stat.homeStatHome, stat.totalHomeStatHome, stat.decimals, stat.suffix)}</td>
                                                <td></td>
                                                <td align="center" className={awayClass}>{formatStat(stat.awayStat, stat.totalAwayStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={awayAtAwayClass}>{formatStat(stat.awayStatAway, stat.totalAwayStatAway, stat.decimals, stat.suffix)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td className="stats-category"></td>
                                        <td colSpan="5" className="stats-category">Passes</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <td>at Home</td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>Away</td>
                                    </tr>
                                    {[

                                        {
                                            label: "Passes",
                                            homeStat: stats.home_season_stats.passes_passes,
                                            homeStatHome: stats.home_season_home_stats.passes_passes,
                                            awayStat: stats.away_season_stats.passes_passes,
                                            awayStatAway: stats.away_season_away_stats.passes_passes,
                                            format: true,
                                            suffix: '',
                                            decimals: 1,
                                        },
                                        {
                                            label: "Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_passesSuccessful / stats.home_season_stats.passes_passes * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_passesSuccessful / stats.home_season_home_stats.passes_passes * 100,
                                            awayStat: stats.away_season_stats.passes_passesSuccessful / stats.away_season_stats.passes_passes * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_passesSuccessful / stats.away_season_away_stats.passes_passes * 100,
                                            format: true,
                                            suffix: '%',
                                            decimals: 1,
                                        },
                                        {
                                            label: "Back Passes (% of Total Passes)",
                                            homeStat: stats.home_season_stats.passes_backPasses,
                                            totalHomeStat: stats.home_season_stats.passes_passes,
                                            homeStatHome: stats.home_season_home_stats.passes_backPasses,
                                            totalHomeStatHome: stats.home_season_home_stats.passes_passes,
                                            awayStat: stats.away_season_stats.passes_backPasses,
                                            totalAwayStat: stats.away_season_stats.passes_passes,
                                            awayStatAway: stats.away_season_away_stats.passes_backPasses,
                                            totalAwayStatAway: stats.away_season_away_stats.passes_passes,
                                            format: true,
                                            invert: true,
                                            decimals: 1,
                                            suffix: '',
                                        },
                                        {
                                            label: "Back Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_backPassesSuccessful / stats.home_season_stats.passes_backPasses * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_backPassesSuccessful / stats.home_season_home_stats.passes_backPasses * 100,
                                            awayStat: stats.away_season_stats.passes_backPassesSuccessful / stats.away_season_stats.passes_backPasses * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_backPassesSuccessful / stats.away_season_away_stats.passes_backPasses * 100,
                                            format: true,
                                            suffix: '%',
                                            decimals: 1,
                                        },
                                        {
                                            label: "Forward Passes (% of Total Passes)",
                                            homeStat: stats.home_season_stats.passes_forwardPasses,
                                            totalHomeStat: stats.home_season_stats.passes_passes,
                                            homeStatHome: stats.home_season_home_stats.passes_forwardPasses,
                                            totalHomeStatHome: stats.home_season_home_stats.passes_passes,
                                            awayStat: stats.away_season_stats.passes_forwardPasses,
                                            totalAwayStat: stats.away_season_stats.passes_passes,
                                            awayStatAway: stats.away_season_away_stats.passes_forwardPasses,
                                            totalAwayStatAway: stats.away_season_away_stats.passes_passes,
                                            format: true,
                                            decimals: 1,
                                            suffix: '',
                                        },
                                        {
                                            label: "Forward Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_forwardPassesSuccessful / stats.home_season_stats.passes_forwardPasses * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_forwardPassesSuccessful / stats.home_season_home_stats.passes_forwardPasses * 100,
                                            awayStat: stats.away_season_stats.passes_forwardPassesSuccessful / stats.away_season_stats.passes_forwardPasses * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_forwardPassesSuccessful / stats.away_season_away_stats.passes_forwardPasses * 100,
                                            format: true,
                                            suffix: '%',
                                            decimals: 1,
                                        },
                                        {
                                            label: "Lateral Passes (% of Total Passes)",
                                            homeStat: stats.home_season_stats.passes_lateralPasses,
                                            totalHomeStat: stats.home_season_stats.passes_passes,
                                            homeStatHome: stats.home_season_home_stats.passes_lateralPasses,
                                            totalHomeStatHome: stats.home_season_home_stats.passes_passes,
                                            awayStat: stats.away_season_stats.passes_lateralPasses,
                                            totalAwayStat: stats.away_season_stats.passes_passes,
                                            awayStatAway: stats.away_season_away_stats.passes_lateralPasses,
                                            totalAwayStatAway: stats.away_season_away_stats.passes_passes,
                                            format: false,
                                            suffix: '',
                                            decimals : 1,
                                        },
                                        {
                                            label: "Lateral Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_lateralPassesSuccessful / stats.home_season_stats.passes_lateralPasses * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_lateralPassesSuccessful / stats.home_season_home_stats.passes_lateralPasses * 100,
                                            awayStat: stats.away_season_stats.passes_lateralPassesSuccessful / stats.away_season_stats.passes_lateralPasses * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_lateralPassesSuccessful / stats.away_season_away_stats.passes_lateralPasses * 100,
                                            format: true,
                                            suffix: '%',
                                            decimals: 1,

                                        },

                                        {
                                            label: "Progressive Passes (% of Total Passes)",
                                            homeStat: stats.home_season_stats.passes_progressivePasses,
                                            totalHomeStat: stats.home_season_stats.passes_passes,
                                            homeStatHome: stats.home_season_home_stats.passes_progressivePasses,
                                            totalHomeStatHome: stats.home_season_home_stats.passes_passes,
                                            awayStat: stats.away_season_stats.passes_progressivePasses,
                                            totalAwayStat: stats.away_season_stats.passes_passes,
                                            awayStatAway: stats.away_season_away_stats.passes_progressivePasses,
                                            totalAwayStatAway: stats.away_season_away_stats.passes_passes,
                                            format: true,
                                            decimals: 2,
                                            suffix: '',
                                        },
                                        {
                                            label: "Progressive Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_progressivePassesSuccessful / stats.home_season_stats.passes_progressivePasses * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_progressivePassesSuccessful / stats.home_season_home_stats.passes_progressivePasses * 100,
                                            awayStat: stats.away_season_stats.passes_progressivePassesSuccessful / stats.away_season_stats.passes_progressivePasses * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_progressivePassesSuccessful / stats.away_season_away_stats.passes_progressivePasses * 100,
                                            format: true,
                                            suffix: '%',
                                            decimals: 2,
                                        },
                                        {
                                            label: "Key Passes (% of Total Passes)",
                                            homeStat: stats.home_season_stats.passes_keyPasses,
                                            totalHomeStat: stats.home_season_stats.passes_passes,
                                            homeStatHome: stats.home_season_home_stats.passes_keyPasses,
                                            totalHomeStatHome: stats.home_season_home_stats.passes_passes,
                                            awayStat: stats.away_season_stats.passes_keyPasses,
                                            totalAwayStat: stats.away_season_stats.passes_passes,
                                            awayStatAway: stats.away_season_away_stats.passes_keyPasses,
                                            totalAwayStatAway: stats.away_season_away_stats.passes_passes,
                                            format: true,
                                            suffix: '',
                                            decimals: 2,
                                        },
                                        {
                                            label: "Key Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_keyPassesSuccessful / stats.home_season_stats.passes_keyPasses * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_keyPassesSuccessful / stats.home_season_home_stats.passes_keyPasses * 100,
                                            awayStat: stats.away_season_stats.passes_keyPassesSuccessful / stats.away_season_stats.passes_keyPasses * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_keyPassesSuccessful / stats.away_season_away_stats.passes_keyPasses * 100,
                                            format: true,
                                            decimals: 2,
                                            suffix: '%',
                                        },
                                        {
                                            label: "Smart Passes (% of Total Passes)",
                                            homeStat: stats.home_season_stats.passes_smartPasses,
                                            totalHomeStat: stats.home_season_stats.passes_passes,
                                            homeStatHome: stats.home_season_home_stats.passes_smartPasses,
                                            totalHomeStatHome: stats.home_season_home_stats.passes_passes,
                                            awayStat: stats.away_season_stats.passes_smartPasses,
                                            totalAwayStat: stats.away_season_stats.passes_passes,
                                            awayStatAway: stats.away_season_away_stats.passes_smartPasses,
                                            totalAwayStatAway: stats.away_season_away_stats.passes_passes,
                                            format: true,
                                            suffix: '',
                                            decimals: 2,
                                        },
                                        {
                                            label: "Smart Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_smartPassesSuccessful / stats.home_season_stats.passes_smartPasses * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_smartPassesSuccessful / stats.home_season_home_stats.passes_smartPasses * 100,
                                            awayStat: stats.away_season_stats.passes_smartPassesSuccessful / stats.away_season_stats.passes_smartPasses * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_smartPassesSuccessful / stats.away_season_away_stats.passes_smartPasses * 100,
                                            format: true,
                                            suffix: '%',
                                            decimals: 2,
                                        },
                                        {
                                            label: "Through Passes",
                                            homeStat: stats.home_season_stats.passes_throughPasses,
                                            homeStatHome: stats.home_season_home_stats.passes_throughPasses,
                                            awayStat: stats.away_season_stats.passes_throughPasses,
                                            awayStatAway: stats.away_season_away_stats.passes_throughPasses,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Through Passes Successful",
                                            homeStat: stats.home_season_stats.passes_throughPassesSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_throughPassesSuccessful,
                                            awayStat: stats.away_season_stats.passes_throughPassesSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_throughPassesSuccessful,
                                            format: true,
                                            suffix: '',
                                        },

                                        {
                                            label: "Avg Pass Length",
                                            homeStat: stats.home_season_stats.passes_avgPassLength,
                                            homeStatHome: stats.home_season_home_stats.passes_avgPassLength,
                                            awayStat: stats.away_season_stats.passes_avgPassLength,
                                            awayStatAway: stats.away_season_away_stats.passes_avgPassLength,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Avg Pass to Final Third Length",
                                            homeStat: stats.home_season_stats.passes_avgPassToFinalThirdLength,
                                            homeStatHome: stats.home_season_home_stats.passes_avgPassToFinalThirdLength,
                                            awayStat: stats.away_season_stats.passes_avgPassToFinalThirdLength,
                                            awayStatAway: stats.away_season_away_stats.passes_avgPassToFinalThirdLength,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses Blocked",
                                            homeStat: stats.home_season_stats.passes_crossesBlocked,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesBlocked,
                                            awayStat: stats.away_season_stats.passes_crossesBlocked,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesBlocked,
                                            format: true,
                                            suffix: '',
                                            invert: true,
                                        },
                                        {
                                            label: "Crosses from Left Flank",
                                            homeStat: stats.home_season_stats.passes_crossesFromLeftFlank,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesFromLeftFlank,
                                            awayStat: stats.away_season_stats.passes_crossesFromLeftFlank,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesFromLeftFlank,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses from Left Flank Successful",
                                            homeStat: stats.home_season_stats.passes_crossesFromLeftFlankSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesFromLeftFlankSuccessful,
                                            awayStat: stats.away_season_stats.passes_crossesFromLeftFlankSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesFromLeftFlankSuccessful,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses from Right Flank",
                                            homeStat: stats.home_season_stats.passes_crossesFromRightFlank,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesFromRightFlank,
                                            awayStat: stats.away_season_stats.passes_crossesFromRightFlank,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesFromRightFlank,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses from Right Flank Successful",
                                            homeStat: stats.home_season_stats.passes_crossesFromRightFlankSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesFromRightFlankSuccessful,
                                            awayStat: stats.away_season_stats.passes_crossesFromRightFlankSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesFromRightFlankSuccessful,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses High",
                                            homeStat: stats.home_season_stats.passes_crossesHigh,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesHigh,
                                            awayStat: stats.away_season_stats.passes_crossesHigh,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesHigh,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses Low",
                                            homeStat: stats.home_season_stats.passes_crossesLow,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesLow,
                                            awayStat: stats.away_season_stats.passes_crossesLow,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesLow,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses Successful",
                                            homeStat: stats.home_season_stats.passes_crossesSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesSuccessful,
                                            awayStat: stats.away_season_stats.passes_crossesSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesSuccessful,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Crosses Total",
                                            homeStat: stats.home_season_stats.passes_crossesTotal,
                                            homeStatHome: stats.home_season_home_stats.passes_crossesTotal,
                                            awayStat: stats.away_season_stats.passes_crossesTotal,
                                            awayStatAway: stats.away_season_away_stats.passes_crossesTotal,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Deep Completed Passes",
                                            homeStat: stats.home_season_stats.passes_deepCompletedPasses,
                                            homeStatHome: stats.home_season_home_stats.passes_deepCompletedPasses,
                                            awayStat: stats.away_season_stats.passes_deepCompletedPasses,
                                            awayStatAway: stats.away_season_away_stats.passes_deepCompletedPasses,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Deep Completed Passes Successful %",
                                            homeStat: stats.home_season_stats.passes_deepCompletedPassesSuccessful / stats.home_season_stats.passes_deepCompletedPasses * 100,
                                            homeStatHome: stats.home_season_home_stats.passes_deepCompletedPassesSuccessful / stats.home_season_home_stats.passes_deepCompletedPasses * 100,
                                            awayStat: stats.away_season_stats.passes_deepCompletedPassesSuccessful / stats.away_season_stats.passes_deepCompletedPasses * 100,
                                            awayStatAway: stats.away_season_away_stats.passes_deepCompletedPassesSuccessful / stats.away_season_away_stats.passes_deepCompletedPasses * 100,
                                            format: true,
                                            suffix: '%',
                                            decimals: 0,
                                        },

                                        {
                                            label: "Long Passes",
                                            homeStat: stats.home_season_stats.passes_longPasses,
                                            homeStatHome: stats.home_season_home_stats.passes_longPasses,
                                            awayStat: stats.away_season_stats.passes_longPasses,
                                            awayStatAway: stats.away_season_away_stats.passes_longPasses,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Long Passes Successful",
                                            homeStat: stats.home_season_stats.passes_longPassesSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_longPassesSuccessful,
                                            awayStat: stats.away_season_stats.passes_longPassesSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_longPassesSuccessful,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Match Tempo",
                                            homeStat: stats.home_season_stats.passes_matchTempo,
                                            homeStatHome: stats.home_season_home_stats.passes_matchTempo,
                                            awayStat: stats.away_season_stats.passes_matchTempo,
                                            awayStatAway: stats.away_season_away_stats.passes_matchTempo,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Pass to Final Thirds",
                                            homeStat: stats.home_season_stats.passes_passToFinalThirds,
                                            homeStatHome: stats.home_season_home_stats.passes_passToFinalThirds,
                                            awayStat: stats.away_season_stats.passes_passToFinalThirds,
                                            awayStatAway: stats.away_season_away_stats.passes_passToFinalThirds,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Pass to Final Thirds Successful",
                                            homeStat: stats.home_season_stats.passes_passToFinalThirdsSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_passToFinalThirdsSuccessful,
                                            awayStat: stats.away_season_stats.passes_passToFinalThirdsSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_passToFinalThirdsSuccessful,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Pass to Penalty Areas",
                                            homeStat: stats.home_season_stats.passes_passToPenaltyAreas,
                                            homeStatHome: stats.home_season_home_stats.passes_passToPenaltyAreas,
                                            awayStat: stats.away_season_stats.passes_passToPenaltyAreas,
                                            awayStatAway: stats.away_season_away_stats.passes_passToPenaltyAreas,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Pass to Penalty Areas Successful",
                                            homeStat: stats.home_season_stats.passes_passToPenaltyAreasSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_passToPenaltyAreasSuccessful,
                                            awayStat: stats.away_season_stats.passes_passToPenaltyAreasSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_passToPenaltyAreasSuccessful,
                                            format: true,
                                            suffix: '',
                                        },

                                        {
                                            label: "Short Medium Passes",
                                            homeStat: stats.home_season_stats.passes_shortMediumPasses,
                                            homeStatHome: stats.home_season_home_stats.passes_shortMediumPasses,
                                            awayStat: stats.away_season_stats.passes_shortMediumPasses,
                                            awayStatAway: stats.away_season_away_stats.passes_shortMediumPasses,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Short Medium Passes Successful",
                                            homeStat: stats.home_season_stats.passes_shortMediumPassesSuccessful,
                                            homeStatHome: stats.home_season_home_stats.passes_shortMediumPassesSuccessful,
                                            awayStat: stats.away_season_stats.passes_shortMediumPassesSuccessful,
                                            awayStatAway: stats.away_season_away_stats.passes_shortMediumPassesSuccessful,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Assists",
                                            homeStat: stats.home_season_stats.passes_assists,
                                            homeStatHome: stats.home_season_home_stats.passes_assists,
                                            awayStat: stats.away_season_stats.passes_assists,
                                            awayStatAway: stats.away_season_away_stats.passes_assists,
                                            format: true,
                                            suffix: '',
                                        },
                                        {
                                            label: "Shot Assists",
                                            homeStat: stats.home_season_stats.passes_shotAssists,
                                            homeStatHome: stats.home_season_home_stats.passes_shotAssists,
                                            awayStat: stats.away_season_stats.passes_shotAssists,
                                            awayStatAway: stats.away_season_away_stats.passes_shotAssists,
                                            format: true,
                                            suffix: '',
                                        },


                                    ].map((stat) => {
                                        const [homeClass, awayClass] = getCellClass(stat.homeStat, stat.awayStat, stat.format, stat.invert);
                                        const [homeAtHomeClass, awayAtAwayClass] = getCellClass(stat.homeStatHome, stat.awayStatAway, stat.format, stat.invert);

                                        return (
                                            <tr className="hover-row" key={stat.label}>
                                                <td align="right">{stat.label}:</td>
                                                <td align="center" className={homeClass}>{formatStat(stat.homeStat, stat.totalHomeStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={homeAtHomeClass}>{formatStat(stat.homeStatHome, stat.totalHomeStatHome, stat.decimals, stat.suffix)}</td>
                                                <td></td>
                                                <td align="center" className={awayClass}>{formatStat(stat.awayStat, stat.totalAwayStat, stat.decimals, stat.suffix)}</td>
                                                <td align="center" className={awayAtAwayClass}>{formatStat(stat.awayStatAway, stat.totalAwayStatAway, stat.decimals, stat.suffix)}</td>
                                            </tr>
                                        );
                                    }
                                    )}







                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </div>
        </div>
    );

};

export default UpcomingMatchupModal;