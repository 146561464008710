import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from './AuthContext';

if (process.env.REACT_APP_BUILD_ENV === 'production') {
    console.log = function () {};
}

const PermissionsContext = createContext(null);

export const PermissionsProvider = ({ children }) => {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [meta, setMeta] = useState(false);
    const { user, logout } = useAuth();

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axiosInstance.get('/user/get-user-permissions/');
                const { roles, permissions, meta } = response.data;

                setRoles(roles);
                setPermissions(permissions);
                setMeta(meta);
            } catch (error) {
                console.error("Error fetching permissions", error);
                if (error.response && error.response.status === 403) {
                    logout();
                }
            }
        };

        if (user) {
            fetchPermissions();
        }
    }, [user, logout]);

    return (
        <PermissionsContext.Provider value={{ roles, permissions, meta }}>
            {children}
        </PermissionsContext.Provider>
    );
};

export const usePermissions = () => useContext(PermissionsContext);
