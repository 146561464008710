import React, { useCallback, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import logo from '../SC_logo.svg';

if (process.env.REACT_APP_BUILD_ENV === 'production ') {
    console.log = function () { };
}


const clientId = "670696151629-tqtv16ka0ba5hv34n4v9tt8kcjdkne02.apps.googleusercontent.com";

const loadGoogleScript = () => {
    return new Promise((resolve) => {
        if (document.getElementById('google-client-script')) {
            resolve();
            return;
        }
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.id = 'google-client-script';
        script.onload = resolve;
        document.body.appendChild(script);
    });
};

const LoginPage = () => {
    const { user, login } = useAuth();
    const navigate = useNavigate();

    const handleCredentialResponse = useCallback((response) => {
        console.log('handleCredentialResponse called with:', response);

        axiosInstance.post('/google/auth/', { token: response.credential })
            .then(response => {
                console.log('API response received:', response);
                if (response.data.access && response.data.refresh) {
                    console.log('Access and refresh tokens received:', response.data);

                    const userData = {
                        accessToken: response.data.access,
                        refreshToken: response.data.refresh
                    };
                    login(userData); 
                } else {
                    console.warn('No tokens received in response:', response.data);
                }
            })
            .catch(error => {
                console.error('Login API Call Failed:', error.response || error);
            });
    }, [login]);

    useEffect(() => {
        console.log('Initializing Google accounts');
        loadGoogleScript().then(() => {
            if (window.google && window.google.accounts) {
                window.google.accounts.id.initialize({
                    client_id: clientId,
                    callback: handleCredentialResponse
                });
                console.log('Rendering Google sign-in button');
                window.google.accounts.id.renderButton(
                    document.getElementById("signInDiv"),
                    { theme: "outline", size: "large" }
                );
            } else {
                console.error('Google accounts script not loaded');
            }
        });
    }, [handleCredentialResponse]);

    useEffect(() => {
        console.log('useEffect for navigation triggered with user:', user);
        if (user && user.accessToken) {
            console.log('User authenticated, navigating to dashboard');
            navigate('/dashboard');
        } else {
            console.log('User not authenticated, staying on login page');
            navigate('/login');
        }
    }, [user, navigate]);

    const styles = {
        loginContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            maxWidth: '320px',
            margin: '100px auto',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            textAlign: 'center',
        },
        logo: {
            width: '200px',
            marginBottom: '20px',
        }
    };

    return (
        <div style={styles.loginContainer}>
            <img src={logo} alt="ShortCircuit Logo" style={styles.logo} />
            <div id="signInDiv"></div>
        </div>
    );
};

export default LoginPage;
