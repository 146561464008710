import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style/Core.css'; // Import the CSS file for styling

if (process.env.REACT_APP_BUILD_ENV === 'production ') {
    console.log = function () { };
}


const GenerateAPIKey = () => {
    const [sessionKey, setSessionKey] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiKeyExists, setApiKeyExists] = useState(false);
    const baseUrl = process.env.REACT_APP_FOOTBALL_DATA_API_URL;

    useEffect(() => {
        const checkApiKey = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/user/create-api-key/');
                setSessionKey(response.data.session_key);
                setApiKeyExists(true);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setApiKeyExists(false);
                } else {
                    console.error("Error retrieving session key", error);
                    toast.error("Failed to retrieve session key");
                }
            } finally {
                setLoading(false);
            }
        };

        checkApiKey();
    }, []);

    useEffect(() => {
        if (apiKey) {
            console.log("API Key:", apiKey);
        }
    }, [apiKey]);

    const generateApiKey = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/user/create-api-key/');
            setSessionKey(response.data.session_key);
            console.log("Response API Key:", response.data.key);
            setApiKey(response.data.key);
            setApiKeyExists(true);
            toast.success("API Key generated successfully!");

            // Optionally store session_key in localStorage
            localStorage.setItem('session_key', response.data.session_key);
        } catch (error) {
            console.error("Error generating API key", error);
            toast.error("Failed to generate API key");
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(apiKey).then(() => {
            toast.success("API Key copied to clipboard!");
        }, (err) => {
            console.error("Failed to copy API key: ", err);
            toast.error("Failed to copy API key");
        });
    };

    return (
        <div>
            <h2>Generate API Key</h2>
            {apiKeyExists ? (
                <div>
                    <p>Your API Key is not displayed for security reasons. You can generate a new API key below.</p>
                    {sessionKey && (
                        <div>
                            <p>You can access the API documentation at the following links:</p>
                            <ul>
                                <li>
                                    <a
                                        href={`${baseUrl}/swagger/?session_key=${sessionKey}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: '#80CFFF' }}
                                    >
                                        Swagger Documentation
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`${baseUrl}/redoc/?session_key=${sessionKey}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: '#80CFFF' }}
                                    >
                                        ReDoc Documentation
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )}
                    <button className="generateApiKey-button" onClick={generateApiKey} disabled={loading}>
                        {loading ? 'Generating...' : 'Generate New API Key'}
                    </button>
                    {apiKey && (
                        <div className="generateApiKey-key-container">
                            <h3>Your New API Key:</h3>
                            <pre className="generateApiKey-key">{apiKey}</pre>
                            <button className="generateApiKey-copy-button" onClick={copyToClipboard}>Copy to Clipboard</button>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <p>No active API key found. Please generate a new API key.</p>
                    <button className="generateApiKey-button" onClick={generateApiKey} disabled={loading}>
                        {loading ? 'Generating...' : 'Generate API Key'}
                    </button>
                    {apiKey && (
                        <div className="generateApiKey-key-container">
                            <h3>Your API Key:</h3>
                            <pre className="generateApiKey-key">{apiKey}</pre>
                            <button className="generateApiKey-copy-button" onClick={copyToClipboard}>Copy to Clipboard</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default GenerateAPIKey;
