import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const HeatMap = ({ width = 600, height = 375, data = [] }) => {
  const svgRef = useRef();

  // Define adjustable variables
  const transparency = 0.12; // Adjust transparency here
  const bandwidth = 25; // Adjust bandwidth here for smoothness

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    // Clear previous drawings
    svg.selectAll('*').remove();

    // Create scales
    const xScale = d3.scaleLinear().domain([0, 100]).range([0, width]);
    const yScale = d3.scaleLinear().domain([0, 100]).range([0, height]);

    // Create a density function
    const densityData = d3.contourDensity()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y))
      .size([width, height])
      .bandwidth(bandwidth) // Use the adjustable bandwidth
      (data);

    // Create color scale
    const maxDensity = d3.max(densityData, d => d.value);
    const colorScale = d3.scaleSequential(d3.interpolateYlOrRd)
      .domain([0, maxDensity]);

    // Append density contours to the SVG
    svg.append('g')
      .selectAll('path')
      .data(densityData)
      .enter().append('path')
      .attr('d', d3.geoPath())
      .attr('fill', d => colorScale(d.value))
      .attr('stroke', 'none')
      .attr('opacity', transparency); // Use the adjustable transparency
  }, [width, height, data, transparency, bandwidth]);

  return <g ref={svgRef}></g>;
};

export default HeatMap;
    