import React from 'react';
import HeatMap from './HeatMap';
import logo from '../../SC_logo.svg';
import '../style/Core.css';
import SoccerPitch from './SoccerPitch';
import Network from './Network';

const VisualizationWrapper = ({ svgId, type, datatype, meta, period, data, width = 600, height = 375 }) => {
    console.log('VisualizationWrapper:', type, datatype, width, height)
    const labelHeight = 125;  


    return (
        <div id={svgId} style={{ width, backgroundColor: '#0E1426' }}>
            <svg width={width} height={height + labelHeight}>
                <defs>
                    <clipPath id="clip">
                        <rect x="0" y="0" width={width} height={height} />
                    </clipPath>
                </defs>
                <rect x="0" y="0" width={width-2} height={labelHeight-2} fill="none" stroke="#80CFFF" strokeWidth="2" />
                <rect x="0" y="0" width="15%" height={labelHeight} fill="#0E1426" fillOpacity={1} />
                <rect x="15%" y="0" width="85%" height={labelHeight} fill="#BBCCD5" fillOpacity={1} />
                <line x1="15%" y1="0" x2="15%" y2={labelHeight} stroke="#80CFFF" strokeWidth="2" opacity={1} />
                <text x="18%" y="20" textAnchor="left" fontSize="16" fontWeight="bold" fill="#0E1426" opacity={1}>Competition: {meta.competition} ({meta.season})</text>
                <text x="18%" y="40" textAnchor="left" fontSize="16" fill="#0E1426" opacity={1}>{new Date(meta.date).toDateString()} - {meta.label}</text>
                <text x="18%" y="80" textAnchor="left" fontSize="16" fill="#0E1426" opacity={1}>
                    {meta.team} 
                    {meta.period && ` ${meta.period}`}
                    {meta.player && ` (${meta.player})`}
                </text>
                <text x="18%" y="100" textAnchor="left" fontSize="16" fill="#0E1426" opacity={1}>Type: {datatype.charAt(0).toUpperCase() + datatype.slice(1).toLowerCase()} ({type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()})</text>
                <image
                    href={logo}
                    x="3%"
                    y="40"
                    height="40"
                    width="40"
                />
                <g transform={`translate(0, ${labelHeight})`} clipPath="url(#clip)">
                    <SoccerPitch width={width} height={height} />
                    {type === 'heatmap' && <HeatMap width={width} height={height} data={data} />}
                    {type === 'network' && <Network width={width} height={height} data={data} networkType='sets' />}
                </g>
            </svg>
        </div>
    );
};

export default VisualizationWrapper;
