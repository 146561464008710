import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

export const saveImage = (svgId) => {
    const element = document.getElementById(svgId);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
    html2canvas(element).then(canvas => {
        canvas.toBlob(blob => {
            saveAs(blob, `img-${svgId}-${formattedDate}.png`);
        });
    });
};
