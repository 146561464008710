import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const SoccerPitch = ({ width = 600, height = 375}) => {
  console.log('SoccerPitch:', width, height);
  const svgRef = useRef();
  const pitchHeight = height;

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear previous drawings
    const g = svg.append('g').attr('transform', `translate(0, 0)`);
    drawSoccerPitch(g, width, pitchHeight);
  }, [width, pitchHeight]);

  const drawSoccerPitch = (svg, width, height) => {
    const pitchColor = '#419144'; 
    const lineColor = '#FFFFFF';
    const goalLineColor = '#bd2020';  

    svg.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', pitchColor);

    // Center line
    svg.append('line')
      .attr('x1', width / 2)
      .attr('y1', 0)
      .attr('x2', width / 2)
      .attr('y2', height)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    // Center circle
    const centerCircleRadius = (10 / 120) * width;
    svg.append('circle')
      .attr('cx', width / 2)
      .attr('cy', height / 2)
      .attr('r', centerCircleRadius)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2)
      .attr('fill', 'none');

    // Add penalty areas (18-yard box)
    const penaltyAreaHeight = (18 / 120) * width;
    const penaltyAreaWidth = (44 / 75) * height;

    // Left penalty area
    svg.append('line')
      .attr('x1', penaltyAreaHeight)
      .attr('y1', (height - penaltyAreaWidth) / 2)
      .attr('x2', penaltyAreaHeight)
      .attr('y2', (height + penaltyAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', 0)
      .attr('y1', (height - penaltyAreaWidth) / 2)
      .attr('x2', penaltyAreaHeight)
      .attr('y2', (height - penaltyAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', 0)
      .attr('y1', (height + penaltyAreaWidth) / 2)
      .attr('x2', penaltyAreaHeight)
      .attr('y2', (height + penaltyAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    // Right penalty area
    svg.append('line')
      .attr('x1', width - penaltyAreaHeight)
      .attr('y1', (height - penaltyAreaWidth) / 2)
      .attr('x2', width)
      .attr('y2', (height - penaltyAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', width - penaltyAreaHeight)
      .attr('y1', (height + penaltyAreaWidth) / 2)
      .attr('x2', width)
      .attr('y2', (height + penaltyAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', width - penaltyAreaHeight)
      .attr('y1', (height - penaltyAreaWidth) / 2)
      .attr('x2', width - penaltyAreaHeight)
      .attr('y2', (height + penaltyAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    // Penalty spots
    const penaltySpotDistance = (12 / 120) * width;

    // Left penalty spot
    svg.append('circle')
      .attr('cx', penaltySpotDistance)
      .attr('cy', height / 2)
      .attr('r', 2)
      .attr('fill', lineColor);

    // Right penalty spot
    svg.append('circle')
      .attr('cx', width - penaltySpotDistance)
      .attr('cy', height / 2)
      .attr('r', 2)
      .attr('fill', lineColor);

    // Add goal areas (6-yard box)
    const goalAreaHeight = (6 / 120) * width;
    const goalAreaWidth = (20 / 75) * height;

    // Left goal area
    svg.append('line')
      .attr('x1', goalAreaHeight)
      .attr('y1', (height - goalAreaWidth) / 2)
      .attr('x2', goalAreaHeight)
      .attr('y2', (height + goalAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', 0)
      .attr('y1', (height - goalAreaWidth) / 2)
      .attr('x2', goalAreaHeight)
      .attr('y2', (height - goalAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', 0)
      .attr('y1', (height + goalAreaWidth) / 2)
      .attr('x2', goalAreaHeight)
      .attr('y2', (height + goalAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    // Right goal area
    svg.append('line')
      .attr('x1', width - goalAreaHeight)
      .attr('y1', (height - goalAreaWidth) / 2)
      .attr('x2', width)
      .attr('y2', (height - goalAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', width - goalAreaHeight)
      .attr('y1', (height + goalAreaWidth) / 2)
      .attr('x2', width)
      .attr('y2', (height + goalAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    svg.append('line')
      .attr('x1', width - goalAreaHeight)
      .attr('y1', (height - goalAreaWidth) / 2)
      .attr('x2', width - goalAreaHeight)
      .attr('y2', (height + goalAreaWidth) / 2)
      .attr('stroke', lineColor)
      .attr('stroke-width', 2);

    // Goal lines
    const goalWidth = (8 / 75) * height; // 8 yards, adjusted to the pitch height

    // Left goal line
    svg.append('line')
      .attr('x1', 0)
      .attr('y1', (height - goalWidth) / 2)
      .attr('x2', 0)
      .attr('y2', (height + goalWidth) / 2)
      .attr('stroke', goalLineColor)
      .attr('stroke-width', 3);

    // Right goal line
    svg.append('line')
      .attr('x1', width)
      .attr('y1', (height - goalWidth) / 2)
      .attr('x2', width)
      .attr('y2', (height + goalWidth) / 2)
      .attr('stroke', goalLineColor)
      .attr('stroke-width', 3);
  };

  return <g ref={svgRef}></g>;
};

export default SoccerPitch;
