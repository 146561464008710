import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axiosInstance';
import './MatchVideo.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VideoModal from './VideoModal';

if (process.env.REACT_APP_BUILD_ENV === 'production') {
    console.log = function () { };
}

const Main = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [areas, setAreas] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [selectedAreaId, setSelectedAreaId] = useState(null);
    const [selectedCompetitionId, setSelectedCompetitionId] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [matchVideos, setMatchVideos] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [selectedMatchId, setSelectedMatchId] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const handleRequestError = useCallback((err, context) => {
        if (err.response && err.response.status === 403) {
            toast.error('Session expired. Please log in again.');
            logout();
            navigate('/login');
        } else {
            toast.error(`An error occurred while ${context}.`);
        }
    }, [logout, navigate]);

    useEffect(() => {
        const fetchAreas = async () => {
            try {
                const response = await axiosInstance.get('/areas/');
                setAreas(response.data);
            } catch (err) {
                handleRequestError(err, 'fetching areas');
            }
        };

        fetchAreas();
    }, [handleRequestError]);

    useEffect(() => {
        if (selectedAreaId) {
            const fetchCompetitions = async () => {
                try {
                    const response = await axiosInstance.get(`/competitions/?area=${selectedAreaId}`);
                    setCompetitions(response.data);
                } catch (err) {
                    handleRequestError(err, 'fetching competitions');
                }
            };

            fetchCompetitions();
        }
    }, [selectedAreaId, handleRequestError]);

    useEffect(() => {
        if (selectedCompetitionId) {
            const fetchSeasons = async () => {
                try {
                    const response = await axiosInstance.get(`/seasons/?competition=${selectedCompetitionId}`);
                    setSeasons(response.data);
                } catch (err) {
                    handleRequestError(err, 'fetching seasons');
                }
            };

            fetchSeasons();
        }
    }, [selectedCompetitionId, handleRequestError]);

    const handleAreaChange = event => {
        setSelectedAreaId(event.target.value);
        setSelectedCompetitionId('');
        setSelectedSeason(null);
        setSelectedTeam(null);
        setMatchVideos([]);
        setTeams([]);
    };

    const handleCompetitionChange = event => {
        setSelectedCompetitionId(event.target.value);
        setSelectedSeason(null);
        setSelectedTeam(null);
        setMatchVideos([]);
        setTeams([]);
    };

    const handleSeasonChange = event => {
        const selectedSeasonId = event.target.value;
        setSelectedSeason(selectedSeasonId);
        fetchMatchVideos(selectedSeasonId);
    };

    const handleTeamChange = event => {
        setSelectedTeam(event.target.value);
    };

    const fetchMatchVideos = async (seasonId) => {
        try {
            const response = await axiosInstance.get(`/matches/season-available-video/?season=${seasonId}`, { timeout: 600000 });
            const sortedVideos = response.data.sort((a, b) => new Date(a.dateutc) - new Date(b.dateutc));
            setMatchVideos(sortedVideos);
            setTeams(getDistinctTeams(sortedVideos));
        } catch (err) {
            handleRequestError(err, 'fetching match videos');
        }
    };

    const getDistinctTeams = (matches) => {
        const teamsSet = new Set();
        matches.forEach(match => {
            teamsSet.add(match.home_team_name);
            teamsSet.add(match.away_team_name);
        });
        return Array.from(teamsSet).sort();
    };

    const filteredMatchVideos = selectedTeam
        ? matchVideos.filter(match => match.home_team_name === selectedTeam || match.away_team_name === selectedTeam)
        : matchVideos;

    const updateVideoQuality = async (matchId) => {
        try {
            const response = await axiosInstance.get(`/matches/update-video-quality/?match=${matchId}`, { timeout: 600000 });
            const updatedMatch = response.data;
            setMatchVideos(prevMatchVideos => prevMatchVideos.map(match => match.wyId === updatedMatch.wyId ? updatedMatch : match));
        } catch (err) {
            if (err.response && err.response.status === 500 && err.response.data.error === 'No video offsets found') {
                toast.error('Unable to get offsets, match video may not be available.');
                setMatchVideos(prevMatchVideos =>
                    prevMatchVideos.map(match => match.wyId === matchId
                        ? {
                            ...match,
                            videos: {
                                ...match.videos,
                                "1H": { ...match.videos["1H"], status: "Unavailable" },
                                "2H": { ...match.videos["2H"], status: "Unavailable" },
                                "E1": { ...match.videos["E1"], status: "Unavailable" },
                                "E2": { ...match.videos["E2"], status: "Unavailable" },
                                "P": { ...match.videos["P"], status: "Unavailable" }
                            }
                        }
                        : match
                    )
                );
            } else {
                handleRequestError(err, 'updating video quality');
            }
        }
    };

    const downloadMatchVideo = async (matchId) => {
        toast.info('Video downloading. This may take a while.');
        try {
            const response = await axiosInstance.get(`/matches/download-match-video/?match=${matchId}`, { timeout: 1800000 });
            const updatedMatch = response.data;
            setMatchVideos(prevMatchVideos => prevMatchVideos.map(match => match.wyId === updatedMatch.wyId ? updatedMatch : match));
        } catch (err) {
            handleRequestError(err, 'downloading match video');
        }
    };

    const openVideoModal = async (matchId, period) => {
        try {
            const response = await axiosInstance.get(`/matches/generate-presigned-video-url/?match=${matchId}&period=${period}`);
            setVideoUrl(response.data.url);
            setSelectedMatchId(matchId);
            setSelectedPeriod(period);
            setModalOpen(true);
        } catch (err) {
            handleRequestError(err, 'fetching video URL');
        }
    };

    const closeVideoModal = () => {
        axiosInstance.get(`/matches/close-video-stream/?match=${selectedMatchId}&period=${selectedPeriod}`);
        setModalOpen(false);
        setVideoUrl('');
    };

    const renderVideoCell = (videoStatus, period, matchId) => {
        const buttonClass = videoStatus === "downloaded" ? "button button-green" : "button";
        if (videoStatus === "not played") {
            return "N/A";
        }
        if (videoStatus === "available") {
            return <button className="button" onClick={() => downloadMatchVideo(matchId)}>Download</button>;
        }
        if (videoStatus === "downloaded") {
            return <button className={buttonClass} onClick={() => openVideoModal(matchId, period)}>View</button>;
        }
        return videoStatus || "Unknown";
    };

    const isPeriodNotPlayed = (videos) => {
        const periods = ["1H", "2H"];
        return periods.every(period => videos[period]?.status === "downloaded" || videos[period]?.status === "available");
    };

    return (
        <div className="matchVideo-container">
            {areas.length > 0 ? (
                <div>
                    <select className="matchVideo-select" onChange={handleAreaChange} value={selectedAreaId || ''}>
                        <option value="">Select Area</option>
                        {areas.map(area => (
                            <option key={area.id} value={area.id}>{area.name}</option>
                        ))}
                    </select>

                    {selectedAreaId && competitions.length > 0 && (
                        <select className="matchVideo-select" onChange={handleCompetitionChange} value={selectedCompetitionId || ''}>
                            <option value="">Select Competition</option>
                            {competitions.map(competition => (
                                <option key={competition.wyId} value={competition.wyId}>{competition.name} ({
                                    competition.gender === 'male' ? 'M' : 'F'}{competition.divisionLevel})</option>
                            ))}
                        </select>
                    )}

                    {selectedCompetitionId && seasons.length > 0 && (
                        <select className="matchVideo-select" onChange={handleSeasonChange} value={selectedSeason || ''}>
                            <option value="">Select Season</option>
                            {seasons.map(season => (
                                <option key={season.wyId} value={season.wyId}>{season.name}</option>
                            ))}
                        </select>
                    )}

                    {selectedSeason && matchVideos.length > 0 && (
                        <>
                            <h2>Competition: {competitions.find(comp => comp.wyId.toString() === selectedCompetitionId)?.name}</h2>
                            <h3>Season: {seasons.find(season => season.wyId.toString() === selectedSeason)?.name}</h3>

                            <select className="matchVideo-select" onChange={handleTeamChange} value={selectedTeam || ''}>
                                <option value="">Filter Team</option>
                                {teams.map(team => (
                                    <option key={team} value={team}>{team}</option>
                                ))}
                            </select>

                            <div className="matchVideo-results">
                                <table className="matchVideo-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Home Team</th>
                                            <th>Away Team</th>
                                            <th>Quality</th>
                                            <th>1H</th>
                                            <th>2H</th>
                                            <th>E1</th>
                                            <th>E2</th>
                                            <th>P</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredMatchVideos.map(match => {
                                            const videos = match.videos;
                                            const periodStatuses = ["E1", "E2", "P"];
                                            periodStatuses.forEach(period => {
                                                if (isPeriodNotPlayed(videos) && videos[period]?.status === "unknown") {
                                                    videos[period].status = "not played";
                                                }
                                            });

                                            return (
                                                <tr key={match.wyId}>
                                                    <td>{new Date(match.dateutc).toLocaleString()}</td>
                                                    <td>{match.home_team_name}</td>
                                                    <td>{match.away_team_name}</td>
                                                    <td>
                                                        {videos["1H"]?.quality || (
                                                            videos["1H"]?.status !== "downloaded" && videos["1H"]?.status !== "not played" && <button className="button" onClick={() => updateVideoQuality(match.wyId)}>Update</button>
                                                        )}
                                                    </td>
                                                    <td>{renderVideoCell(videos["1H"]?.status, "1H", match.wyId)}</td>
                                                    <td>{renderVideoCell(videos["2H"]?.status, "2H", match.wyId)}</td>
                                                    <td>{renderVideoCell(videos["E1"]?.status, "E1", match.wyId)}</td>
                                                    <td>{renderVideoCell(videos["E2"]?.status, "E2", match.wyId)}</td>
                                                    <td>{renderVideoCell(videos["P"]?.status, "P", match.wyId)}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <VideoModal isOpen={modalOpen} onClose={closeVideoModal} videoUrl={videoUrl} matchId={selectedMatchId} period={selectedPeriod} />
                        </>
                    )}
                </div>
            ) : (
                <p className="matchVideo-message">No filter data available.</p>
            )}
        </div>
    );
};

export default Main;
