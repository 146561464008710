import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import './style/Core.css';
import logo from '../SC_logo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import UpcomingMatchupModal from './UpcomingMatchupModal';
import { toast } from 'react-toastify';
import { usePermissions } from '../context/PermissionsContext';

if (process.env.REACT_APP_BUILD_ENV === 'production') {
    console.log = function () { };
}

const UpcomingMatches = () => {
    const [groupedMatches, setGroupedMatches] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [currentMatchId, setCurrentMatchId] = useState(null);
    const { meta: { is_superuser: isSuperUser } } = usePermissions();

    useEffect(() => {
        const fetchMatches = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/stats/prematch/', { timeout: 600000 });
                const matches = response.data;
                const grouped = groupMatchesByTimeAndCompetition(matches);
                setGroupedMatches(grouped);
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    toast.error('Session expired. Please log in again.');
                    logout();
                    navigate('/login');
                } else {
                    toast.error('Failed to load matches. Please try again later.');
                    setError(err.message);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, [logout, navigate]);

    const openModal = (matchId) => {
        setCurrentMatchId(matchId);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentMatchId(null);
    };

    const TypingText = ({ text }) => {
        const [displayedText, setDisplayedText] = useState('');

        useEffect(() => {
            let index = 0;
            const intervalId = setInterval(() => {
                if (index < text.length) {
                    setDisplayedText(displayedText => displayedText + text.charAt(index));
                    index++;
                } else {
                    clearInterval(intervalId);
                }
            }, 30);

            return () => clearInterval(intervalId);
        }, [text]);

        return <div className="typing-text">{displayedText}</div>;
    };

    const groupMatchesByTimeAndCompetition = (matches) => {
        const grouped = {};
        matches.forEach(match => {
            const dateString = new Date(match.date).toLocaleDateString(undefined, {
                weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'
            });
            if (!grouped[dateString]) {
                grouped[dateString] = {};
            }
            const compSeason = `${match.area_name} - ${match.competition_name} (${match.season_name})`;
            if (!grouped[dateString][compSeason]) {
                grouped[dateString][compSeason] = [];
            }
            grouped[dateString][compSeason].push(match);
        });
        return grouped;
    };

    const handleUpdateSeason = async (season_id) => {
        try {
            toast.info('Triggering season update... This may take a while');
            const response = await axiosInstance.post('/seasons/update-season-matches/', { season_id }, { timeout: 600000 });
            toast.success('Season update complete');
            const updatedMatches = response.data.data;

            setGroupedMatches(prevGroupedMatches => {
                // Create a copy of the existing grouped matches
                const newGroupedMatches = { ...prevGroupedMatches };

                // Iterate over the updated matches and replace the old match data
                updatedMatches.forEach(updatedMatch => {
                    const dateString = new Date(updatedMatch.date).toLocaleDateString(undefined, {
                        weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'
                    });
                    const compSeason = `${updatedMatch.area_name} - ${updatedMatch.competition_name} (${updatedMatch.season_name})`;

                    if (newGroupedMatches[dateString] && newGroupedMatches[dateString][compSeason]) {
                        const matchIndex = newGroupedMatches[dateString][compSeason].findIndex(
                            match => match.match === updatedMatch.match
                        );
                        if (matchIndex !== -1) {
                            newGroupedMatches[dateString][compSeason][matchIndex] = updatedMatch;
                        } else {
                            newGroupedMatches[dateString][compSeason].push(updatedMatch);
                        }
                    } else {
                        if (!newGroupedMatches[dateString]) {
                            newGroupedMatches[dateString] = {};
                        }
                        if (!newGroupedMatches[dateString][compSeason]) {
                            newGroupedMatches[dateString][compSeason] = [];
                        }
                        newGroupedMatches[dateString][compSeason].push(updatedMatch);
                    }
                });

                return newGroupedMatches;
            });

            console.log(response.data);
        } catch (err) {
            toast.error('Failed to trigger season update');
            console.error(err);
        }
    };

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <img src={logo} alt="Logo" className="rotating-logo" />
                <TypingText text="L o a d i n g . . ." />
            </div>
        );
    }
    if (error) return <p>Error loading matches: {error}</p>;

    return (
        <>
            <UpcomingMatchupModal isOpen={modalOpen} onClose={closeModal} matchId={currentMatchId} />
            {Object.entries(groupedMatches).map(([date, competitions]) => {
                return (
                    <>
                        <div className="upcomingMatchesDateHeader" key={date}>
                            <h4 style={{ padding: 0, margin: 0 }}>{date}</h4>
                        </div>
                        {Object.entries(competitions).map(([compSeason, matches]) => {
                            return (
                                <>
<div className="upcomingMatchesCompetitionHeader" key={compSeason}>
    <strong style={{ marginRight: '15px' }}>{compSeason}</strong>
    {isSuperUser && (
        <button
            className="update-season-button"
            onClick={() => handleUpdateSeason(matches[0].season_id)}
        >
            Update Season
        </button>
    )}
</div>

                                    {matches.map(match => {
                                        return (
                                            <div className="upcomingMatchesList" key={match.match}>
                                                <h4>{match.home_team_name} vs {match.away_team_name} ({match.match})</h4>
                                                <table className="upcomingMatchStatTable">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '150px' }}></th>
                                                            <th style={{ width: '20px' }} title="Matches Played">MP</th>
                                                            <th style={{ width: '20px' }} title="Matches Won">W</th>
                                                            <th style={{ width: '20px' }} title="Matches Draw">D</th>
                                                            <th style={{ width: '20px' }} title="Matches Lost">L</th>
                                                            <th style={{ width: '20px' }} title="Total Points">Pts</th>
                                                            <th style={{ width: '20px' }} title="Goals For">GF</th>
                                                            <th style={{ width: '20px' }} title="Goals Against">GA</th>
                                                            <th style={{ width: '30px' }} title="Avg xG per match">XG</th>
                                                            <th style={{ width: '30px' }} title="Avg xG/Shot per match">XG/S</th>
                                                            <th style={{ width: '30px' }} title="Avg xGC per match">XGC</th>
                                                            <th style={{ width: '30px' }} title="Avg goals per match">Goals</th>
                                                            <th style={{ width: '30px' }} title="Avg shots per match">Shots</th>
                                                            <th style={{ width: '30px' }} title="Avg shots on target per match">SOT</th>
                                                            <th style={{ width: '30px' }} title="Avg possession % per match">Poss%</th>
                                                            <th style={{ width: '30px' }} title="Avg pass completion % per match">Pass%</th>
                                                            <th style={{ width: '30px' }} title="Avg tempo per match">Tempo</th>
                                                            <th style={{ width: '30px' }} title="Avg passes per defensive action per match">PPDA</th>
                                                            <th style={{ width: '30px' }} title="Avg challenge intensity per match">CI</th>
                                                            <th style={{ width: '30px' }} title="Avg offensive duels per match">OD</th>
                                                            <th style={{ width: '30px' }} title="Win % offensive duels per match">OD%</th>
                                                            <th style={{ width: '30px' }} title="Avg defensive duels per match">DD</th>
                                                            <th style={{ width: '30px' }} title="Win % defensive duels per match">DD%</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="home-team" onClick={() => openModal(match.match)}>
                                                            <td align="right">{match.home_team_name}</td>
                                                            <td>
                                                                {match.home_totalPlayed}
                                                                {match.home_team_stat_matches && (
                                                                    <span style={{ color: '#F5424D' }}>
                                                                        ({match.home_team_stat_matches - match.home_totalPlayed})
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>{match.home_totalWins}</td>
                                                            <td>{match.home_totalDraws}</td>
                                                            <td>{match.home_totalLosses}</td>
                                                            <td>{match.home_totalPoints}</td>
                                                            <td>{match.home_totalGoalsFor}</td>
                                                            <td>{match.home_totalGoalsAgainst}</td>
                                                            <td>{match.home_season_stats?.general_xg ? match.home_season_stats.general_xg.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_stats?.general_xgPerShot ? match.home_season_stats.general_xgPerShot.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.home_xgc ? match.home_xgc.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}
                                                            </td>
                                                            <td>{match.home_season_stats?.general_goals ? match.home_season_stats.general_goals.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_stats?.general_shots ? match.home_season_stats.general_shots.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_stats?.general_shotsOnTarget ? match.home_season_stats.general_shotsOnTarget.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_stats?.possession_possessionPercent ? match.home_season_stats.possession_possessionPercent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : 'N/A'}</td>
                                                            <td>
                                                                {match.home_season_stats.passes_passes !== null && match.home_season_stats.passes_passesSuccessful !== null ?
                                                                    ((match.home_season_stats.passes_passesSuccessful / match.home_season_stats.passes_passes * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'
                                                                }
                                                            </td>
                                                            <td>{match.home_season_stats?.passes_matchTempo ? match.home_season_stats.passes_matchTempo.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_stats?.defence_ppda ? match.home_season_stats.defence_ppda.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_stats?.duels_challengeIntensity ? match.home_season_stats.duels_challengeIntensity.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_stats?.duels_offensiveDuels ? match.home_season_stats.duels_offensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.home_season_stats?.duels_offensiveDuelsSuccessful && match.home_season_stats?.duels_offensiveDuels > 0
                                                                    ? ((match.home_season_stats.duels_offensiveDuelsSuccessful / match.home_season_stats.duels_offensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                            <td>{match.home_season_stats?.duels_defensiveDuels ? match.home_season_stats.duels_defensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.home_season_stats?.duels_defensiveDuelsSuccessful && match.home_season_stats?.duels_defensiveDuels > 0
                                                                    ? ((match.home_season_stats.duels_defensiveDuelsSuccessful / match.home_season_stats.duels_defensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        {/* Home team at home */}
                                                        <tr className="home-team-home" onClick={() => openModal(match.match)}>
                                                            <td align="right">at Home</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{match.home_season_home_stats?.general_xg ? match.home_season_home_stats.general_xg.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_home_stats?.general_xgPerShot ? match.home_season_home_stats.general_xgPerShot.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.home_xgc_home ? match.home_xgc_home.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}
                                                            </td>
                                                            <td>{match.home_season_home_stats?.general_goals ? match.home_season_home_stats.general_goals.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_home_stats?.general_shots ? match.home_season_home_stats.general_shots.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_home_stats?.general_shotsOnTarget ? match.home_season_home_stats.general_shotsOnTarget.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_home_stats?.possession_possessionPercent ? match.home_season_home_stats.possession_possessionPercent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : 'N/A'}</td>
                                                            <td>
                                                                {match.home_season_home_stats.passes_passes !== null && match.home_season_home_stats.passes_passesSuccessful !== null ?
                                                                    ((match.home_season_home_stats.passes_passesSuccessful / match.home_season_home_stats.passes_passes * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'
                                                                }
                                                            </td>
                                                            <td>{match.home_season_home_stats?.passes_matchTempo ? match.home_season_home_stats.passes_matchTempo.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_home_stats?.defence_ppda ? match.home_season_home_stats.defence_ppda.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_home_stats?.duels_challengeIntensity ? match.home_season_home_stats.duels_challengeIntensity.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.home_season_home_stats?.duels_offensiveDuels ? match.home_season_home_stats.duels_offensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.home_season_home_stats?.duels_offensiveDuelsSuccessful && match.home_season_home_stats?.duels_offensiveDuels > 0
                                                                    ? ((match.home_season_home_stats.duels_offensiveDuelsSuccessful / match.home_season_home_stats.duels_offensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                            <td>{match.home_season_home_stats?.duels_defensiveDuels ? match.home_season_home_stats.duels_defensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.home_season_home_stats?.duels_defensiveDuelsSuccessful && match.home_season_home_stats?.duels_defensiveDuels > 0
                                                                    ? ((match.home_season_home_stats.duels_defensiveDuelsSuccessful / match.home_season_home_stats.duels_defensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        {/* Away Team General Stats */}
                                                        <tr className="away-team" onClick={() => openModal(match.match)}>
                                                            <td align="right">{match.away_team_name}</td>
                                                            <td>
                                                                {match.away_totalPlayed}
                                                                {match.away_team_stat_matches && (
                                                                    <span style={{ color: '#F5424D' }}>
                                                                        ({match.away_team_stat_matches - match.away_totalPlayed})
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>{match.away_totalWins}</td>
                                                            <td>{match.away_totalDraws}</td>
                                                            <td>{match.away_totalLosses}</td>
                                                            <td>{match.away_totalPoints}</td>
                                                            <td>{match.away_totalGoalsFor}</td>
                                                            <td>{match.away_totalGoalsAgainst}</td>
                                                            <td>{match.away_season_stats?.general_xg ? match.away_season_stats.general_xg.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_stats?.general_xgPerShot ? match.away_season_stats.general_xgPerShot.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.away_xgc ? match.away_xgc.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}
                                                            </td>
                                                            <td>{match.away_season_stats?.general_goals ? match.away_season_stats.general_goals.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_stats?.general_shots ? match.away_season_stats.general_shots.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_stats?.general_shotsOnTarget ? match.away_season_stats.general_shotsOnTarget.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_stats?.possession_possessionPercent ? match.away_season_stats.possession_possessionPercent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : 'N/A'}</td>
                                                            <td>
                                                                {match.away_season_stats.passes_passes !== null && match.away_season_stats.passes_passesSuccessful !== null ?
                                                                    ((match.away_season_stats.passes_passesSuccessful / match.away_season_stats.passes_passes * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'
                                                                }
                                                            </td>
                                                            <td>{match.away_season_stats?.passes_matchTempo ? match.away_season_stats.passes_matchTempo.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_stats?.defence_ppda ? match.away_season_stats.defence_ppda.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_stats?.duels_challengeIntensity ? match.away_season_stats.duels_challengeIntensity.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_stats?.duels_offensiveDuels ? match.away_season_stats.duels_offensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.away_season_stats?.duels_offensiveDuelsSuccessful && match.away_season_stats?.duels_offensiveDuels > 0
                                                                    ? ((match.away_season_stats.duels_offensiveDuelsSuccessful / match.away_season_stats.duels_offensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                            <td>{match.away_season_stats?.duels_defensiveDuels ? match.away_season_stats.duels_defensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.away_season_stats?.duels_defensiveDuelsSuccessful && match.away_season_stats?.duels_defensiveDuels > 0
                                                                    ? ((match.away_season_stats.duels_defensiveDuelsSuccessful / match.away_season_stats.duels_defensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        {/* away team away stats */}
                                                        <tr className="away-team-away" onClick={() => openModal(match.match)}>
                                                            <td align="right">Away</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{match.away_season_away_stats?.general_xg ? match.away_season_away_stats.general_xg.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_away_stats?.general_xgPerShot ? match.away_season_away_stats.general_xgPerShot.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.away_xgc_away ? match.away_xgc_away.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}
                                                            </td>
                                                            <td>{match.away_season_away_stats?.general_goals ? match.away_season_away_stats.general_goals.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_away_stats?.general_shots ? match.away_season_away_stats.general_shots.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_away_stats?.general_shotsOnTarget ? match.away_season_away_stats.general_shotsOnTarget.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_away_stats?.possession_possessionPercent ? match.away_season_away_stats.possession_possessionPercent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : 'N/A'}</td>
                                                            <td>
                                                                {match.away_season_away_stats.passes_passes !== null && match.away_season_away_stats.passes_passesSuccessful !== null ?
                                                                    ((match.away_season_away_stats.passes_passesSuccessful / match.away_season_away_stats.passes_passes * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'
                                                                }
                                                            </td>
                                                            <td>{match.away_season_away_stats?.passes_matchTempo ? match.away_season_away_stats.passes_matchTempo.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_away_stats?.defence_ppda ? match.away_season_away_stats.defence_ppda.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_away_stats?.duels_challengeIntensity ? match.away_season_away_stats.duels_challengeIntensity.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>{match.away_season_away_stats?.duels_offensiveDuels ? match.away_season_away_stats.duels_offensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.away_season_away_stats?.duels_offensiveDuelsSuccessful && match.away_season_away_stats?.duels_offensiveDuels > 0
                                                                    ? ((match.away_season_away_stats.duels_offensiveDuelsSuccessful / match.away_season_away_stats.duels_offensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                            <td>{match.away_season_away_stats?.duels_defensiveDuels ? match.away_season_away_stats.duels_defensiveDuels.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                                                            <td>
                                                                {match.away_season_away_stats?.duels_defensiveDuelsSuccessful && match.away_season_away_stats?.duels_defensiveDuels > 0
                                                                    ? ((match.away_season_away_stats.duels_defensiveDuelsSuccessful / match.away_season_away_stats.duels_defensiveDuels * 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%')
                                                                    : 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        );
                                    })}
                                </>
                            );
                        })}
                    </>
                );
            })}
        </>
    );
};

export default UpcomingMatches;
