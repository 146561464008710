import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api/axiosInstance';
import VisualizationWrapper from './VisualizationWrapper';
import { saveImage } from './Utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../style/Core.css';

const VisualizationDashboard = () => {
    const [areas, setAreas] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [teams, setTeams] = useState([]);
    const [matches, setMatches] = useState([]);
    const [filteredLineup, setFilteredLineup] = useState([]); // State for filtered lineup data
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCompetition, setSelectedCompetition] = useState('');
    const [selectedSeason, setSelectedSeason] = useState('');
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedMatch, setSelectedMatch] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState(''); // State for selected period
    const [selectedPlayer, setSelectedPlayer] = useState(''); // State for selected player
    const [visualizationType, setVisualizationType] = useState('');
    const [dataType, setDataType] = useState('');
    const [metaData, setMetaData] = useState(null);
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const navigate = useNavigate();

    const dataTypesForVisualization = {
        heatmap: ['passes', 'shots', 'duels', 'touches', 'accelerations'],
        network: ['passes', 'carries']
    };

    const periods = ['', '1H', '2H', 'ET1', 'ET2'];

    const resetSelections = (level) => {
        if (level <= 1) {
            setSelectedCompetition('');
        }
        if (level <= 2) {
            setSelectedSeason('');
        }
        if (level <= 3) {
            setSelectedTeam('');
        }
        if (level <= 4) {
            setSelectedMatch('');
        }
        if (level <= 5) {
            setSelectedPeriod('');
            setSelectedPlayer('');
            setVisualizationType('');
            setDataType('');
            setFilteredLineup([]);
        }
        setMetaData(null);
        setData(null);
        setErrorMessage('');
    };

    // Fetch areas on component mount
    useEffect(() => {
        axiosInstance.get('/areas/')
            .then(response => setAreas(response.data))
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    toast.error('Session expired. Please login again.');
                    navigate('/login');
                } else {
                    toast.error('Error fetching areas.  Try again later.');
                }
            });
    }, [navigate]);

    // Fetch competitions when selectedArea changes
    useEffect(() => {
        if (selectedArea) {
            resetSelections(1);
            axiosInstance.get(`/competitions/?area=${selectedArea}`)
                .then(response => setCompetitions(response.data))
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        toast.error('Session expired. Please login again.');
                        navigate('/login');
                    } else {
                        toast.error('Error fetching competitions. Try again later.');
                    }
                });
        }
    }, [selectedArea, navigate]);

    // Fetch seasons when selectedCompetition changes
    useEffect(() => {
        if (selectedCompetition) {
            resetSelections(2);
            axiosInstance.get(`/seasons/?competition=${selectedCompetition}`)
                .then(response => setSeasons(response.data))
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        toast.error('Session expired. Please login again.');
                        navigate('/login');
                    } else {
                        toast.error('Error fetching seasons. Try again later.');
                    }
                });
        }
    }, [selectedCompetition, navigate]);

    // Fetch teams when selectedSeason changes
    useEffect(() => {
        if (selectedSeason) {
            resetSelections(3);
            axiosInstance.get(`/seasons/${selectedSeason}/teams`)
                .then(response => setTeams(response.data))
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        toast.error('Session expired. Please login again.');
                        navigate('/login');
                    } else {
                        toast.error('Error fetching teams. Try again later.');
                    }
                });
        }
    }, [selectedSeason, navigate]);

    // Fetch matches when selectedTeam changes
    useEffect(() => {
        if (selectedTeam) {
            resetSelections(4);
            axiosInstance.get(`/matches/?season=${selectedSeason}&team=${selectedTeam}`)
                .then(response => setMatches(response.data))
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        toast.error('Session expired. Please login again.');
                        navigate('/login');
                    } else {
                        toast.error('Error fetching matches. Try again later.');
                    }
                });
        }
    }, [selectedTeam, selectedSeason, navigate]);

    // Fetch lineup data when selectedMatch changes
    useEffect(() => {
        if (selectedMatch) {
            resetSelections(5);
            axiosInstance.get(`/matches/${selectedMatch}/lineups/`)
                .then(response => {
                    // Filter the lineup data based on the selected team
                    const filtered = response.data.filter(player => player.team === parseInt(selectedTeam));
                    setFilteredLineup(filtered);
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        toast.error('Session expired. Please login again.');
                        navigate('/login');
                    } else {
                        toast.error('Error fetching lineup data. Try again later.');
                        
                    }
                });
        } else {
            setFilteredLineup([]);
        }
    }, [selectedMatch, selectedTeam, navigate]);

    // Fetch visualization data when selectedMatch, dataType, visualizationType, selectedPeriod, or selectedPlayer change
    useEffect(() => {
        if (selectedMatch && dataType && visualizationType) {
            let endpoint;
            if (visualizationType === 'heatmap') {
                endpoint = `/stats/heatmap/?matchid=${selectedMatch}&teamid=${selectedTeam}&type=${dataType}`;
            } else if (visualizationType === 'network') {
                endpoint = `/stats/network/?matchid=${selectedMatch}&teamid=${selectedTeam}&type=${dataType}`;
            } else {
                toast.error('Invalid visualization type.');
                return;
            }

            if (selectedPeriod) {
                endpoint += `&period=${selectedPeriod}`;
            }

            if (selectedPlayer) {
                endpoint += `&playerid=${selectedPlayer}`;
            }

            axiosInstance.get(endpoint)
                .then(response => {
                    if (response.data.error) {
                        setErrorMessage('Event data for the selected match not available.');
                    } else {
                        const meta = response.data.meta;
                        if (selectedPeriod) {
                            meta.period = selectedPeriod;
                        }
                        if (selectedPlayer) {
                            const player = filteredLineup.find(p => p.player === parseInt(selectedPlayer));
                            if (player) {
                                meta.player = player.player_short_name;
                            }
                        }
                        setMetaData(meta);
                        setData(response.data.data);
                        setShowModal(true); // Show the modal when data is received
                        setErrorMessage('');
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        toast.error('Session expired. Please login again.');
                        navigate('/login');
                    } else if (error.response && error.response.status === 404) {
                        toast.warn('Data not available for the selected match.');
                        setErrorMessage('Data not available for the selected match.');
                    } else {
                        toast.error('An error occurred while fetching data.');
                        setErrorMessage('An error occurred while fetching data.');
                    }
                });
        }
    }, [selectedMatch, selectedTeam, dataType, visualizationType, selectedPeriod, selectedPlayer, filteredLineup, navigate]);

    const closeModal = () => {
        setShowModal(false);
        setMetaData(null);
        setData(null);
    };

    return (
        <div className="visualizations-container">
            <div>
                <label>Country:</label>
                <select className="visualizations-select" onChange={(e) => setSelectedArea(e.target.value)} value={selectedArea}>
                    <option value="">Select Country</option>
                    {areas.map(area => (
                        <option key={area.id} value={area.id}>{area.name}</option>
                    ))}
                </select>
            </div>
            {selectedArea && (
                <div>
                    <label>Competition:</label>
                    <select className="visualizations-select" onChange={(e) => setSelectedCompetition(e.target.value)} value={selectedCompetition}>
                        <option value="">Select Competition</option>
                        {competitions.map(competition => (
                            <option key={competition.wyId} value={competition.wyId}>{competition.name}</option>
                        ))}
                    </select>
                </div>
            )}
            {selectedCompetition && (
                <div>
                    <label>Season:</label>
                    <select className="visualizations-select" onChange={(e) => setSelectedSeason(e.target.value)} value={selectedSeason}>
                        <option value="">Select Season</option>
                        {seasons.map(season => (
                            <option key={season.wyId} value={season.wyId}>{season.name}</option>
                        ))}
                    </select>
                </div>
            )}
            {selectedSeason && (
                <div>
                    <label>Team:</label>
                    <select className="visualizations-select" onChange={(e) => setSelectedTeam(e.target.value)} value={selectedTeam}>
                        <option value="">Select Team</option>
                        {teams.map(team => (
                            <option key={team.wyId} value={team.wyId}>{team.name}</option>
                        ))}
                    </select>
                </div>
            )}
            {selectedTeam && (
                <div>
                    <label>Match:</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <select className="visualizations-select" onChange={(e) => setSelectedMatch(e.target.value)} value={selectedMatch}>
                            <option value="">Select Match</option>
                            {matches.map(match => (
                                <option key={match.wyId} value={match.wyId}>{new Date(match.dateutc).toLocaleString()}: {match.label}</option>
                            ))}
                        </select>
                        <select className="visualizations-select" onChange={(e) => setSelectedPeriod(e.target.value)} value={selectedPeriod} style={{ marginLeft: '10px' }}>
                            <option value="">Full Game</option>
                            {periods.map(period => (
                                <option key={period} value={period}>{period}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            {selectedMatch && (
                <div>
                    <label>Player:</label>
                    <select className="visualizations-select" onChange={(e) => setSelectedPlayer(e.target.value)} value={selectedPlayer}>
                        <option value="">All Players</option>
                        {filteredLineup.map(player => (
                            <option key={player.player} value={player.player}>
                                {player.player_short_name}{player.startingXI ? ' - (XI)' : ''}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {selectedMatch && (
                <div>
                    <label>Visualization Type:</label>
                    <select className="visualizations-select" onChange={(e) => setVisualizationType(e.target.value)} value={visualizationType}>
                        <option value="">Select Visualization Type</option>
                        <option value="heatmap">Heatmap</option>
                        <option value="network">Network</option>
                        {/* Add more visualization types as needed */}
                    </select>
                </div>
            )}
            {visualizationType && (
                <div>
                    <label>Data Type:</label>
                    <select className="visualizations-select" onChange={(e) => setDataType(e.target.value)} value={dataType}>
                        <option value="">Select Data Type</option>
                        {dataTypesForVisualization[visualizationType].map(type => (
                            <option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}</option>
                        ))}
                    </select>
                </div>
            )}
            {errorMessage && <div className="visualizations-message">{errorMessage}</div>}
            {showModal && (
                <div className="visualizations-modal">
                    <div className="visualizations-modal-content">
                        <span className="visualizations-modal-close" onClick={closeModal}>&times;</span>
                        <VisualizationWrapper 
                            svgId={`${selectedMatch}-${selectedTeam}-${dataType}-${visualizationType}`}
                            type={visualizationType}
                            datatype={dataType}
                            meta={metaData}
                            data={data}
                            width={600}
                            height={375}
                        />
                        <button className="visualizations-button" onClick={() => saveImage(`${selectedMatch}-${selectedTeam}-${dataType}-${visualizationType}`)}>Save Image</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisualizationDashboard;
