import React, { useState, useRef, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import './MatchVideo.css';
import AnnotationModal from './AnnotationModal';
import axiosInstance from '../../api/axiosInstance';

const CONTROL_WIDTH = 160; // Width for controls and padding
const PADDING = 10; // Additional padding for height

const VideoModal = ({ isOpen, onClose, videoUrl, matchId, period }) => {
    const [clipStart, setClipStart] = useState(null);
    const [clipEnd, setClipEnd] = useState(null);
    const [clipUrl, setClipUrl] = useState(null);
    const [clipping, setClipping] = useState(false);
    const [annotationModalOpen, setAnnotationModalOpen] = useState(false);
    const [modalSize, setModalSize] = useState({ width: 1000, height: 600 });
    const [modalPosition, setModalPosition] = useState({ x: 100, y: 100 });
    const videoRef = useRef(null);
    const rndRef = useRef(null);
    const [videoSize, setVideoSize] = useState(null); // Use state to store video size

    useEffect(() => {
        const fetchVideoSize = async () => {
            try {
                const response = await fetch(videoUrl, {
                    headers: {
                        Range: 'bytes=0-1' // Request the first byte
                    }
                });
                if (response.ok) {
                    const contentRange = response.headers.get('Content-Range');
                    if (contentRange) {
                        // Extract the total file size from the Content-Range header
                        const sizeMatch = contentRange.match(/\/(\d+)$/);
                        if (sizeMatch) {
                            setVideoSize(parseInt(sizeMatch[1], 10));
                            console.log(`Video content length from Content-Range: ${sizeMatch[1]} bytes`);
                        }
                    } else {
                        console.error('Content-Range header is missing');
                    }
                } else {
                    throw new Error(`Failed to fetch video size: ${response.statusText}`);
                }
            } catch (error) {
                console.error('Failed to fetch video size:', error);
            }
        };

        fetchVideoSize();
    }, [videoUrl]);

    useEffect(() => {
        const handleMetadataLoad = () => {
            if (videoRef.current && rndRef.current) {
                const videoWidth = videoRef.current.videoWidth;
                const videoHeight = videoRef.current.videoHeight;
                const aspectRatio = videoWidth / videoHeight;
                const newHeight = (1000 - CONTROL_WIDTH) / aspectRatio + PADDING; // Adjust height based on aspect ratio and padding
                setModalSize({ width: 1000, height: newHeight });
                rndRef.current.updateSize({ width: 1000, height: newHeight });
                console.log(`Video metadata loaded. Video width: ${videoWidth}, height: ${videoHeight}, aspect ratio: ${aspectRatio}`);
            }
        };

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('loadedmetadata', handleMetadataLoad);
            return () => videoElement.removeEventListener('loadedmetadata', handleMetadataLoad);
        }
    }, [videoUrl]);

    useEffect(() => {
        if (annotationModalOpen && videoRef.current) {
            videoRef.current.pause();
            console.log('Video paused because annotation modal is open.');
        }
    }, [annotationModalOpen]);

    if (!isOpen) return null;

    const handleClipStart = () => {
        if (videoRef.current) {
            setClipStart(videoRef.current.currentTime);
            console.log(`Clip start set at: ${videoRef.current.currentTime} seconds`);
        }
    };

    const handleClipEnd = () => {
        if (videoRef.current) {
            setClipEnd(videoRef.current.currentTime);
            console.log(`Clip end set at: ${videoRef.current.currentTime} seconds`);
        }
    };

    const handleCreateClip = () => {
        setClipping(true);
        setClipStart(null);
        setClipEnd(null);
        setClipUrl(null);
        console.log('Creating new clip. Clipping mode activated.');
    };

    const handleSaveClip = async () => {
        if (clipStart !== null && clipEnd !== null && videoRef.current && videoSize !== null) {
            const video = videoRef.current;
            video.pause();
            console.log('Original video paused.');

            try {
                const duration = video.duration;
                console.log(`Video duration: ${duration} seconds`);

                // Generate clip via backend endpoint using axiosInstance
                const response = await axiosInstance.get(`/matches/generate-video-clip/`, {
                    params: {
                        match: matchId,
                        period: period,
                        start: clipStart,
                        end: clipEnd,
                    },
                    responseType: 'blob', 
                    timeout: 600000, 
                });

                // Accept both 200 OK and 206 Partial Content as valid responses
                if (response.status !== 200 && response.status !== 206) {
                    throw new Error(`Failed to generate clip: ${response.statusText}`);
                }

                const clipBlob = response.data;
                const clipUrl = URL.createObjectURL(clipBlob);
                setClipUrl(clipUrl);
                setAnnotationModalOpen(true);
                console.log('Clip URL created and set:', clipUrl);

            } catch (error) {
                console.error('Failed to generate video clip:', error);
                video.play();
                console.log('Resuming original video due to error in fetching the clip.');
            }
        }
    };

    const handleCancelClip = () => {
        setClipStart(null);
        setClipEnd(null);
        setClipUrl(null);
        setClipping(false);
        videoRef.current.src = videoUrl;
        console.log('Clip creation canceled.');
    };

    const handleCloseAnnotationModal = () => {
        setAnnotationModalOpen(false);
        console.log('Annotation modal closed.');
    };

    return (
        <div className="modal-overlay">
            <Rnd
                ref={rndRef}
                default={modalSize}
                size={modalSize}
                position={modalPosition}
                onDragStop={(e, d) => setModalPosition({ x: d.x, y: d.y })}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setModalSize({
                        width: ref.style.width,
                        height: ref.style.height,
                    });
                    setModalPosition(position);
                }}
                minWidth={1000}
                bounds="window"
                className="modal-content"
            >
                <button className="modal-close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="video-wrapper">
                    <video
                        autoPlay
                        controls
                        className="modal-video"
                        ref={videoRef}
                    >
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="clip-tools">
                        <button onClick={handleCreateClip}>Create Clip</button>
                        {clipping && (
                            <>
                                <button onClick={handleClipStart}>Set Start Point</button>
                                <button onClick={handleClipEnd} disabled={clipStart === null}>Set End Point</button>
                                <button onClick={handleSaveClip} disabled={clipStart === null || clipEnd === null}>Save Clip</button>
                                <button onClick={handleCancelClip}>Cancel</button>
                            </>
                        )}
                    </div>
                </div>
            </Rnd>
            {annotationModalOpen && (
                <AnnotationModal
                    isOpen={annotationModalOpen}
                    onClose={handleCloseAnnotationModal}
                    clipUrl={clipUrl}
                    width={modalSize.width}
                    height={modalSize.height}
                    x={modalPosition.x}
                    y={modalPosition.y}
                    controlWidth={CONTROL_WIDTH}
                    padding={PADDING}
                />
            )}
        </div>
    );
};

export default VideoModal;
