// src/api/authHelper.js
import axios from 'axios';

if (process.env.REACT_APP_BUILD_ENV === 'production ') {
    console.log = function () { };
}

const refreshToken = async (refreshToken) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_FOOTBALL_DATA_API_URL}/google/refresh/`, {
            refreshToken: refreshToken,
        });
        if (response.status === 200) {
            return {
                accessToken: response.data.access,
                refreshToken: response.data.refresh,
            };
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

export default refreshToken;
