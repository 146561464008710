import React, { useRef, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import './MatchVideo.css';

const AnnotationModal = ({ isOpen, onClose, clipUrl, width, height, x, y }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            const handleMetadataLoad = () => {
                console.log('Video metadata loaded.');
                console.log('Clip duration:', videoRef.current.duration);
                console.log('Video dimensions:', videoRef.current.videoWidth, 'x', videoRef.current.videoHeight);
            };

            videoRef.current.src = clipUrl;
            console.log('Setting video source to:', clipUrl);
            videoRef.current.load();
            console.log('Video loading initiated.');

            videoRef.current.onloadedmetadata = handleMetadataLoad;

            videoRef.current.ontimeupdate = () => {
                console.log('Current playback position:', videoRef.current.currentTime);
            };

            videoRef.current.onerror = (e) => {
                console.error('Video error:', e);
            };
        }
    }, [clipUrl]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <Rnd
                default={{
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                }}
                size={{ width, height }}
                position={{ x, y }}
                minWidth={1000}
                bounds="window"
                className="modal-content"
            >
                <button className="modal-close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="video-wrapper" style={{ position: 'relative' }}>
                    <video autoPlay controls className="modal-video" ref={videoRef} style={{ width: '100%', height: '100%' }}>
                        <source src={clipUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </Rnd>
        </div>
    );
};

export default AnnotationModal;
