import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const Network = ({ width = 600, height = 375, data = [], networkType = 'sets' }) => {
    console.log('Network:', width, height, data, networkType);

    const svgRef = useRef();

    // Define adjustable variables
    const lineColor = 'black'; // Adjust line color here
    const lineWeight = 2; // Adjust line weight here
    const transparency = 0.8; // Adjust transparency here
    const showArrow = true; // Option to display arrow at the end of the line

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        // Clear previous drawings
        svg.selectAll('*').remove();

        // Create scales
        const xScale = d3.scaleLinear().domain([0, 100]).range([0, width]);
        const yScale = d3.scaleLinear().domain([0, 100]).range([0, height]);

        // Define a function to draw arrows
        if (showArrow) {
            svg.append('defs').append('marker')
                .attr('id', 'arrow')
                .attr('viewBox', '0 0 10 10')
                .attr('refX', '5')
                .attr('refY', '5')
                .attr('markerWidth', '6')
                .attr('markerHeight', '6')
                .attr('orient', 'auto-start-reverse')
                .append('path')
                .attr('d', 'M 0 0 L 10 5 L 0 10 z')
                .attr('fill', lineColor);
        }

        if (networkType === 'sets') {
            // Log the raw data to understand its structure
            console.log('Raw Data:', data);

            // Filter out invalid data points
            const validData = data.filter(d => {
                const valid = d['start.x'] !== undefined && d['start.y'] !== undefined && d['end.x'] !== undefined && d['end.y'] !== undefined;
                if (!valid) {
                    console.log('Invalid data point:', d);
                }
                return valid;
            });

            console.log('Valid Data:', validData);

            // Draw lines for start and end locations
            svg.append('g')
                .selectAll('line')
                .data(validData)
                .enter().append('line')
                .attr('x1', d => {
                    const x = xScale(d['start.x']);
                    console.log('x1:', x, 'start.x:', d['start.x']);
                    return x;
                })
                .attr('y1', d => {
                    const y = yScale(d['start.y']);
                    console.log('y1:', y, 'start.y:', d['start.y']);
                    return y;
                })
                .attr('x2', d => {
                    const x = xScale(d['end.x']);
                    console.log('x2:', x, 'end.x:', d['end.x']);
                    return x;
                })
                .attr('y2', d => {
                    const y = yScale(d['end.y']);
                    console.log('y2:', y, 'end.y:', d['end.y']);
                    return y;
                })
                .attr('stroke', lineColor)
                .attr('stroke-width', lineWeight)
                .attr('opacity', transparency)
                .attr('marker-end', showArrow ? 'url(#arrow)' : null); // Add arrow if showArrow is true
        }
    }, [width, height, data, networkType, lineColor, lineWeight, transparency, showArrow]);

    return <g ref={svgRef}></g>;
};

export default Network;
