import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../api/axiosInstance';
import { Button, Form, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './style/Core.css';

if (process.env.REACT_APP_BUILD_ENV === 'production ') {
    console.log = function () { };
}

const UserRBACManagement = () => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [appLabels, setAppLabels] = useState({});
    const [showModal, setShowModal] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [editingRole, setEditingRole] = useState(null);
    const [editingPermission, setEditingPermission] = useState(null);
    const [newRole, setNewRole] = useState('');
    const [newRoleToAdd, setNewRoleToAdd] = useState('');
    const [newPermission, setNewPermission] = useState({ role: '', app_label: '', content_types: [], can_view: false, can_add: false, can_edit: false, can_delete: false, can_trigger_updates: false });
    const { logout } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [usersRes, rolesRes, permissionsRes, appLabelsRes] = await Promise.all([
                    axiosInstance.get('user/users/'),
                    axiosInstance.get('user/roles/'),
                    axiosInstance.get('user/permissions/'),
                    axiosInstance.get('user/app-labels-and-content-types/')
                ]);
                setUsers(usersRes.data);
                setRoles(rolesRes.data);
                setPermissions(permissionsRes.data);
                setAppLabels(appLabelsRes.data);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    toast.error('Session expired. Please log in again.');
                    logout();
                } else {
                    toast.error('An error occurred while fetching data.');
                }
            }
        };
        fetchData();
    }, [logout]);

    const handleEditUser = (user) => {
        setEditingUser({ ...user });
        setShowModal('user');
    };

    const handleDeleteUser = async (user) => {
        try {
            await axiosInstance.delete(`user/users/${user.id}/`);
            setUsers((prevUsers) => prevUsers.filter((u) => u.id !== user.id));
            toast.success('User deleted successfully.');
        } catch (error) {
            console.log(error);
            toast.error('An error occurred while deleting the user.');
        }
    };

    const handleRoleChange = (roleId) => {
        setEditingUser((prevUser) => ({
            ...prevUser,
            roles: prevUser.roles.includes(roleId)
                ? prevUser.roles.filter(id => id !== roleId)
                : [...prevUser.roles, roleId],
        }));
    };

    const handleSaveUser = async () => {
        try {
            await axiosInstance.put(`user/users/${editingUser.id}/`, editingUser);
            setUsers((prevUsers) => prevUsers.map((user) => (user.id === editingUser.id ? editingUser : user)));
            setShowModal(null);
            toast.success('User updated successfully.');
        } catch (error) {
            toast.error('An error occurred while saving the user.');
        }
    };

    const handleAddRole = async () => {
        try {
            const response = await axiosInstance.post('user/roles/', { name: newRole });
            setRoles([...roles, response.data]);
            setNewRole('');
            setShowModal(null);
            toast.success('Role added successfully.');
        } catch (error) {
            toast.error('An error occurred while adding the role.');
        }
    };

    const handleDeleteRole = async (role) => {
        try {
            await axiosInstance.delete(`user/roles/${role.id}/`);
            setRoles((prevRoles) => prevRoles.filter((r) => r.id !== role.id));
            toast.success('Role deleted successfully.');
        } catch (error) {
            console.log(error);
            toast.error('An error occurred while deleting the role.');
        }
    };

    const handleEditRole = (role) => {
        setEditingRole({ ...role });
        setShowModal('role');
    };

    const handlePermissionChange = (id, field, value) => {
        setPermissions((prevPermissions) =>
            prevPermissions.map((perm) =>
                perm.id === id ? { ...perm, [field]: value } : perm
            )
        );
    };

    const handleSavePermission = async (id) => {
        try {
            const permission = permissions.find((perm) => perm.id === id);
            await axiosInstance.put(`user/permissions/${id}/`, permission);
            toast.success('Permission updated successfully.');
        } catch (error) {
            toast.error('An error occurred while saving the permission.');
        }
    };

    const handleDeletePermission = async (id) => {
        try {
            await axiosInstance.delete(`user/permissions/${id}/`);
            setPermissions((prevPermissions) => prevPermissions.filter((perm) => perm.id !== id));
            toast.success('Permission deleted successfully.');
        } catch (error) {
            console.log(error);
            toast.error('An error occurred while deleting the permission.');
        }
    };

    const handleAddPermission = async () => {
        try {
            const permissionsToAdd = newPermission.content_types.map(content_type => ({
                ...newPermission,
                role: editingRole.id,
                content_type
            }));
            const response = await Promise.all(permissionsToAdd.map(permission => axiosInstance.post('user/permissions/', permission)));
            setPermissions([...permissions, ...response.map(res => res.data)]);
            setNewPermission({ role: '', app_label: '', content_types: [], can_view: false, can_add: false, can_edit: false, can_delete: false, can_trigger_updates: false });
            setShowModal('role'); // Stay in the role editing modal
            setEditingRole(editingRole); // Ensure the role stays in edit mode
            toast.success('Permission(s) added successfully.');
        } catch (error) {
            toast.error('An error occurred while adding the permission.');
        }
    };

    const handleAppLabelChange = (e) => {
        const app_label = e.target.value;
        setNewPermission((prev) => ({
            ...prev,
            app_label,
            content_types: []
        }));
    };

    const handleContentTypeChange = (e) => {
        const options = e.target.options;
        const selectedOptions = Array.from(options).filter(option => option.selected).map(option => option.value);
        setNewPermission((prev) => ({
            ...prev,
            content_types: selectedOptions
        }));
    };

    const handleAddRoleToUser = () => {
        if (newRoleToAdd) {
            handleRoleChange(newRoleToAdd);
            setNewRoleToAdd('');
        }
    };

    const handleViewRole = (roleId) => {
        const role = roles.find(role => role.id === roleId);
        if (role) {
            setEditingRole(role);
            setShowModal('role');
        }
    };

    return (
        <div className="user-rbac-management-container">
            <h1>User Management</h1>

            {/* Users Table */}
            <h2>Users</h2>
            <div className="user-rbac-management-table-responsive">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Office Location</th>
                            <th>Phone Number</th>
                            <th>Nickname</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr
                                key={user.id}
                                className={
                                    user.is_superuser
                                        ? 'superuser-row'
                                        : user.roles.length === 0
                                        ? 'no-roles-row'
                                        : ''
                                }
                            >
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.office_location}</td>
                                <td>{user.phone_number}</td>
                                <td>{user.nickname}</td>
                                <td>
                                    <Button variant="primary" onClick={() => handleEditUser(user)}>Edit</Button>
                                    <Button variant="danger" onClick={() => handleDeleteUser(user)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {showModal === 'user' && (
                <div className="user-rbac-management-modal">
                    <div className="user-rbac-management-modal-content">
                        <span className="user-rbac-management-modal-close" onClick={() => setShowModal(null)}>&times;</span>
                        <h2>Edit User</h2>
                        {editingUser && (
                            <Form>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editingUser.username}
                                        onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Email</Form.Label>
                                    <Form.Label style={{ color: '#BBCCD5', marginLeft: '10px' }}>{editingUser.email}</Form.Label>
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editingUser.first_name}
                                        onChange={(e) => setEditingUser({ ...editingUser, first_name: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editingUser.last_name}
                                        onChange={(e) => setEditingUser({ ...editingUser, last_name: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Office Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editingUser.office_location}
                                        onChange={(e) => setEditingUser({ ...editingUser, office_location: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editingUser.phone_number}
                                        onChange={(e) => setEditingUser({ ...editingUser, phone_number: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Nickname</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={editingUser.nickname}
                                        onChange={(e) => setEditingUser({ ...editingUser, nickname: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Super User</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        checked={editingUser.is_superuser}
                                        onChange={(e) => setEditingUser({ ...editingUser, is_superuser: e.target.checked })}
                                    />
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label">Roles</Form.Label>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editingUser.roles.map(roleId => (
                                                <tr key={roleId}>
                                                    <td>{roles.find(role => role.id === roleId)?.name}</td>
                                                    <td>
                                                        <Button variant="info" size="sm" onClick={() => handleViewRole(roleId)}>View</Button>
                                                        <Button variant="danger" size="sm" onClick={() => handleRoleChange(roleId)}>Remove</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Form.Group>
                                <Form.Group className="user-rbac-management-form-group">
                                    <Form.Label className="form-label"></Form.Label>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Form.Control
                                                        as="select"
                                                        value={newRoleToAdd}
                                                        onChange={(e) => setNewRoleToAdd(e.target.value)}
                                                        className="mr-2"
                                                    >
                                                        <option value="">Add Role</option>
                                                        {roles.filter(role => !editingUser.roles.includes(role.id)).map(role => (
                                                            <option key={role.id} value={role.id}>{role.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </td>
                                                <td>
                                                    <Button variant="primary" size="sm" onClick={handleAddRoleToUser}>Add</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form.Group>
                            </Form>
                        )}
                        <Button variant="primary" onClick={handleSaveUser}>Save Changes</Button>
                    </div>
                </div>
            )}

            {/* Roles Table */}
            <h2>Roles</h2>
            <div className="user-rbac-management-table-responsive">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Role Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roles.map(role => (
                            <tr key={role.id}>
                                <td>{role.name}</td>
                                <td>
                                    <Button variant="primary" onClick={() => handleEditRole(role)}>Edit</Button>
                                    <Button variant="danger" onClick={() => handleDeleteRole(role)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <Button className="user-rbac-management-button" onClick={() => setShowModal('addRole')}>Add New Role</Button>
                </div>
            </div>

            {showModal === 'addRole' && (
                <div className="user-rbac-management-modal">
                    <div className="user-rbac-management-modal-content">
                        <span className="user-rbac-management-modal-close" onClick={() => setShowModal(null)}>&times;</span>
                        <h2>Add New Role</h2>
                        <Form>
                            <Form.Group>
                                <Form.Label>Role Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newRole}
                                    onChange={(e) => setNewRole(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                        <Button variant="primary" onClick={handleAddRole}>Save Role</Button>
                    </div>
                </div>
            )}

            {showModal === 'role' && (
                <div className="user-rbac-management-modal">
                    <div className="user-rbac-management-modal-content">
                        <span className="user-rbac-management-modal-close" onClick={() => setShowModal(null)}>&times;</span>
                        <h2>Edit Role - {editingRole?.name}</h2>
                        {editingRole && (
                            <div>
                                <h3>Permissions</h3>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>App Label</th>
                                            <th>Model</th>
                                            <th>Can View</th>
                                            <th>Can Add</th>
                                            <th>Can Edit</th>
                                            <th>Can Delete</th>
                                            <th>Can Trigger</th> {/* Add new permission here */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {permissions.filter(perm => perm.role === editingRole.id).map(perm => (
                                            <tr key={perm.id}>
                                                <td>{perm.app_label}</td>
                                                <td>{appLabels[perm.app_label]?.find(ct => ct.id === perm.content_type)?.model}</td>
                                                <td>{perm.can_view ? '✅' : '❌'}</td>
                                                <td>{perm.can_add ? '✅' : '❌'}</td>
                                                <td>{perm.can_edit ? '✅' : '❌'}</td>
                                                <td>{perm.can_delete ? '✅' : '❌'}</td>
                                                <td>{perm.can_trigger_updates ? '✅' : '❌'}</td> {/* Add new permission here */}
                                                <td>
                                                    <Button variant="warning" onClick={() => setEditingPermission(perm)}>Edit</Button>
                                                    <Button variant="danger" onClick={() => handleDeletePermission(perm.id)}>Delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Button className="user-rbac-management-button" onClick={() => setShowModal('addPermission')}>Add New Permission</Button>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showModal === 'addPermission' && (
                <div className="user-rbac-management-modal">
                    <div className="user-rbac-management-modal-content">
                        <span className="user-rbac-management-modal-close" onClick={() => setShowModal('role')}>&times;</span>
                        <h2>Add New Permission</h2>
                        <Form>
                            <Form.Group>
                                <Form.Label>Role</Form.Label>
                                <Form.Label style={{ color: '#BBCCD5', marginLeft: '10px' }}>{editingRole?.name}</Form.Label>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>App Label</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={newPermission.app_label}
                                    onChange={handleAppLabelChange}
                                    className="user-rbac-management-select"
                                >
                                    <option value="">Select App Label</option>
                                    {Object.keys(appLabels).map(appLabel => (
                                        <option key={appLabel} value={appLabel}>{appLabel}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Model Name</Form.Label>
                                <Form.Control
                                    as="select"
                                    multiple
                                    value={newPermission.content_types}
                                    onChange={handleContentTypeChange}
                                    className="user-rbac-management-select"
                                >
                                    {appLabels[newPermission.app_label]?.map(contentType => (
                                        <option key={contentType.id} value={contentType.id}>{contentType.model}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    label="Can View"
                                    checked={newPermission.can_view}
                                    onChange={(e) => setNewPermission({ ...newPermission, can_view: e.target.checked })}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Add"
                                    checked={newPermission.can_add}
                                    onChange={(e) => setNewPermission({ ...newPermission, can_add: e.target.checked })}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Edit"
                                    checked={newPermission.can_edit}
                                    onChange={(e) => setNewPermission({ ...newPermission, can_edit: e.target.checked })}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Delete"
                                    checked={newPermission.can_delete}
                                    onChange={(e) => setNewPermission({ ...newPermission, can_delete: e.target.checked })}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Trigger"
                                    checked={newPermission.can_trigger_updates}
                                    onChange={(e) => setNewPermission({ ...newPermission, can_trigger_updates: e.target.checked })} /* Add new permission here */
                                />
                            </Form.Group>
                        </Form>
                        <Button variant="primary" onClick={handleAddPermission}>Save Permission</Button>
                    </div>
                </div>
            )}

            {showModal === 'editPermission' && editingPermission && (
                <div className="user-rbac-management-modal">
                    <div className="user-rbac-management-modal-content">
                        <span className="user-rbac-management-modal-close" onClick={() => setShowModal('role')}>&times;</span>
                        <h2>Edit Permission</h2>
                        <Form>
                            <Form.Group>
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editingPermission.role}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'role', e.target.value)}
                                    className="user-rbac-management-select"
                                    disabled
                                >
                                    <option value="">{roles.find(role => role.id === editingPermission.role)?.name}</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>App Label</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editingPermission.app_label}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'app_label', e.target.value)}
                                    className="user-rbac-management-select"
                                >
                                    <option value="">Select App Label</option>
                                    {Object.keys(appLabels).map(appLabel => (
                                        <option key={appLabel} value={appLabel}>{appLabel}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Model Name</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editingPermission.content_type}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'content_type', e.target.value)}
                                    className="user-rbac-management-select"
                                >
                                    {appLabels[editingPermission.app_label]?.map(contentType => (
                                        <option key={contentType.id} value={contentType.id}>{contentType.model}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    label="Can View"
                                    checked={editingPermission.can_view}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'can_view', e.target.checked)}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Add"
                                    checked={editingPermission.can_add}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'can_add', e.target.checked)}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Edit"
                                    checked={editingPermission.can_edit}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'can_edit', e.target.checked)}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Delete"
                                    checked={editingPermission.can_delete}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'can_delete', e.target.checked)}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Can Trigger"
                                    checked={editingPermission.can_trigger_updates}
                                    onChange={(e) => handlePermissionChange(editingPermission.id, 'can_trigger_updates', e.target.checked)} /* Add new permission here */
                                />
                            </Form.Group>
                        </Form>
                        <Button variant="primary" onClick={() => handleSavePermission(editingPermission.id)}>Save Permission</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserRBACManagement;
