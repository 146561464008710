import axios from 'axios';

if ( process.env.REACT_APP_BUILD_ENV === 'production' ) {
    console.log = function() {};
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_FOOTBALL_DATA_API_URL,
    timeout: 20000,
});

axiosInstance.interceptors.request.use(
    config => {
        // Retrieve the user object from localStorage
        const userJson = localStorage.getItem('user');
        if (userJson) {
            const user = JSON.parse(userJson);
            const token = user.accessToken;
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } 
        return config;
    },
    error => Promise.reject(error)
);

export default axiosInstance;
